import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import Sidebar from "../common/Sidebar";
import { setUser } from "../../redux/features/userSlice";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import LocalDiningOutlinedIcon from "@mui/icons-material/LocalDiningOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
const AppLayout = () => {
  const user = useSelector((state) => state.user.value);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await authUtils.isAuthenricated();
      if (!user) {
        navigate("/");
      } else {
        dispatch(setUser(user));
        setLoading(false);
      }
    };
    checkAuth();
  }, [navigate, dispatch]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return loading ? (
    <Loading fullHeight />
  ) : (
    <>
      <AppBar
        position="static"
        sx={{
          background: "#efefef",
        }}
        className="shadow-2xl	shadow-lime-700 opacity-80"
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              alignContent: "space-between",
              width: "100%",
              height: "60px",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/Calories (1).png"}
              alt="logo"
            />
          </Box>

          <Box sx={{ flexGrow: 0, mr: 5 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  boxShadow: 24,
                }}
              >
                <Avatar
                  alt={user.username}
                  src="/static/images/avatar/2.jpg"
                  sx={{
                    backgroundColor: "darkslateblue",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={"Dashboard"}
                onClick={() => {
                  navigate("/app/dashboard");
                }}
              >
                <Typography textAlign="center">{"Dashboard"}</Typography>
              </MenuItem>
              <MenuItem
                key={"Logout"}
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate("/");
                }}
              >
                <Typography textAlign="center">{"Logout"}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            flexGrow: 1,
            p: 1,
            width: "max-content",
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/images/background.png)",
            backgroundSize: "300px 300px",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
