import { configureStore } from "@reduxjs/toolkit";
import userRreducer from "./features/userSlice";
import boardsRreducer from "./features/boardSlice";
import favouriteReducer from "./features/favouriteSlice";
import currentBoardSlice from "./features/currentBoardSlice";

export const store = configureStore({
  reducer: {
    user: userRreducer,
    board: boardsRreducer,
    favourites: favouriteReducer,
    currentboard: currentBoardSlice,
  },
});
