import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl, clientBaseUrl } from "../../api/axiosClient";
import { toFixed } from "../../utils/utils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import { lngs } from "../../utils/constVals";
import "./css/NutritionsFactsLabelVertical.css";
import "./css/NutritionsFactsLabelHorizontal.css";

import CloseIcon from "@mui/icons-material/Close";
import assets from "../../assets";
import { Backdrop, Fade, Modal, Box, IconButton, Button } from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: { lg: "55%", xl: "55%" },
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "95%",
  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
  borderRadius: "100px 0px 0px 100px",
};

const NutritionsFactsLabelVertical = (props) => {
  const { t, i18n } = useTranslation();
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState({});
  const [recipePer100Gram, setRecipePer100Gram] = useState({});
  const [servingSize, setservingSize] = useState(2);
  const [direction, setDirection] = useState(1);
  const handleChange = (e) => {
    setservingSize(e.target.value);
  };
  const handleDirectionChange = (e) => {
    setDirection(e.target.value);
  };

  const getTotalValue = (val, totalWieght, res) => {
    return toFixed(
      ((val / totalWieght) * res[0].recipe.per_serving_weight * totalWieght) /
        res[0].recipe.total_cooked_wieght
    );
  };

  useEffect(() => {
    const getRecipeDetails = async () => {
      // if (recipeId === undefined)
      if (
        (props.recipeId === undefined || props.recipeId === "") &&
        recipeId === undefined
      )
        return;
      try {
        var url = "";
        if (recipeId !== undefined) {
          url = recipeId;
        } else url = props.recipeId;
        const { data: res } = await axios.get(
          baseUrl + "ingredients/getall/" + url
        );
        let caloriesSum = 0;
        let saturatedFatSum = 0;
        let totalFatSum = 0;
        let transFatSum = 0;
        let cholesterolSum = 0;
        let SodiumSum = 0;
        let vitaminDSum = 0;
        let calciumSum = 0;
        let totalCarbohydrateSum = 0;
        let dietaryFiberSum = 0;
        let totalSugarsSum = 0;
        let addedSugarsSum = 0;
        let proteinSum = 0;
        let ironSum = 0;
        let potassiumSum = 0;
        let totalWieght = 0;

        res.forEach((key, value) => {
          totalWieght +=
            parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
            parseFloat(key.qty);
          key.nutriFacts.foodNutrients.map((singleRecipeelm, index) => {
            let currentNutrName = singleRecipeelm.nutrient.name;
            let currentAmount =
              (singleRecipeelm.amount / 100) *
              (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                parseFloat(key.qty));
            if (
              currentNutrName === "Energy" &&
              singleRecipeelm.nutrient.unitName === "kcal"
            ) {
              caloriesSum += currentAmount;
            }
            if (
              currentNutrName.includes("(fat)") ||
              currentNutrName.includes("fatty")
            ) {
              totalFatSum += currentAmount;
            }

            if (currentNutrName === "Fatty acids, total saturated") {
              saturatedFatSum += currentAmount;
            }

            if (currentNutrName === "Fatty acids, total trans") {
              transFatSum += currentAmount;
            }

            if (currentNutrName === "Cholesterol") {
              cholesterolSum += currentAmount;
            }
            if (currentNutrName === "Sodium, Na") {
              SodiumSum += currentAmount;
            }
            if (currentNutrName === "Vitamin D (D2 + D3)") {
              vitaminDSum += currentAmount;
            }
            if (currentNutrName === "Calcium, Ca") {
              calciumSum += currentAmount;
            }
            if (currentNutrName === "Carbohydrate, by difference") {
              totalCarbohydrateSum += currentAmount;
            }
            if (currentNutrName === "Fiber, total dietary") {
              dietaryFiberSum += currentAmount;
            }
            if (
              currentNutrName === "Sugars, total including NLEA" ||
              currentNutrName === "Sugars, Total"
            ) {
              totalSugarsSum += currentAmount;
            }
            if (currentNutrName === "Sugars, added") {
              addedSugarsSum += currentAmount;
            }
            if (currentNutrName === "Protein") {
              proteinSum += currentAmount;
            }
            if (currentNutrName === "Iron, Fe") {
              ironSum += currentAmount;
            }
            if (currentNutrName === "Potassium, K") {
              potassiumSum += currentAmount;
            }
          });
        });
        const recipeItem = {
          name: res[0].recipe.title,
          serving_size: res[0].recipe.per_serving_weight,
          calories: getTotalValue(caloriesSum, totalWieght, res),
          total_fat: getTotalValue(totalFatSum, totalWieght, res),
          saturatedFat: getTotalValue(saturatedFatSum, totalWieght, res),
          transFat: getTotalValue(transFatSum, totalWieght, res),
          cholesterol: getTotalValue(cholesterolSum, totalWieght, res),
          Sodium: getTotalValue(SodiumSum, totalWieght, res),
          vitaminD: getTotalValue(vitaminDSum, totalWieght, res),
          calcium: getTotalValue(calciumSum, totalWieght, res),
          totalCarbohydrate: getTotalValue(
            totalCarbohydrateSum,
            totalWieght,
            res
          ),
          dietaryFiber: getTotalValue(dietaryFiberSum, totalWieght, res),
          totalSugars: getTotalValue(totalSugarsSum, totalWieght, res),
          addedSugars: getTotalValue(addedSugarsSum, totalWieght, res),
          protein: getTotalValue(proteinSum, totalWieght, res),
          iron: getTotalValue(ironSum, totalWieght, res),
          potassium: getTotalValue(potassiumSum, totalWieght, res),
          recipeItemDV: {
            total_fat: Math.ceil(
              (getTotalValue(totalFatSum, totalWieght, res) / 78) * 100
            ),
            //migh be 20 instead of 13.5
            saturatedFat: Math.ceil(
              (getTotalValue(saturatedFatSum, totalWieght, res) / 13.5) * 100
            ),
            cholesterol: Math.ceil(
              (getTotalValue(cholesterolSum, totalWieght, res) / 300) * 100
            ),
            Sodium: Math.ceil(
              (getTotalValue(SodiumSum, totalWieght, res) / 2300) * 100
            ),
            vitaminD: Math.ceil(
              (getTotalValue(vitaminDSum, totalWieght, res) / 20) * 100
            ),
            calcium: Math.ceil(
              (getTotalValue(calciumSum, totalWieght, res) / 1300) * 100
            ),
            totalCarbohydrate: Math.ceil(
              (getTotalValue(totalCarbohydrateSum, totalWieght, res) / 275) *
                100
            ),
            dietaryFiber: Math.ceil(
              (getTotalValue(dietaryFiberSum, totalWieght, res) / 28) * 100
            ),
            addedSugars: Math.ceil(
              (getTotalValue(addedSugarsSum, totalWieght, res) / 50) * 100
            ),
            protein: Math.ceil(
              (getTotalValue(proteinSum, totalWieght, res) / 50) * 100
            ),
            iron: Math.ceil(
              (getTotalValue(ironSum, totalWieght, res) / 18) * 100
            ),
            potassium: Math.ceil(
              (getTotalValue(potassiumSum, totalWieght, res) / 4700) * 100
            ),
          },
        };
        // console.log(recipeItem);

        const recipeItemPer100Gram = {
          name: res[0].recipe.title,
          serving_size: "100",
          calories: toFixed(
            (getTotalValue(caloriesSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          total_fat: toFixed(
            toFixed(
              (getTotalValue(totalFatSum, totalWieght, res) /
                recipeItem.serving_size) *
                100
            )
          ),
          saturatedFat: toFixed(
            (getTotalValue(saturatedFatSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          transFat: toFixed(
            (getTotalValue(transFatSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          cholesterol: toFixed(
            (getTotalValue(cholesterolSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          Sodium: toFixed(
            (getTotalValue(SodiumSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          vitaminD: toFixed(
            (getTotalValue(vitaminDSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          calcium: toFixed(
            (getTotalValue(calciumSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          totalCarbohydrate: toFixed(
            (getTotalValue(totalCarbohydrateSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          dietaryFiber: toFixed(
            (getTotalValue(dietaryFiberSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          totalSugars: toFixed(
            (getTotalValue(totalSugarsSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          addedSugars: toFixed(
            (getTotalValue(addedSugarsSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          protein: toFixed(
            (getTotalValue(proteinSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          iron: toFixed(
            (getTotalValue(ironSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          potassium: toFixed(
            (getTotalValue(potassiumSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          recipeItemDV: {
            total_fat: Math.ceil(
              (toFixed(
                (getTotalValue(totalFatSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                78) *
                100
            ),
            //might be 20 instaed of 1.75
            saturatedFat: Math.ceil(
              (getTotalValue(
                getTotalValue(saturatedFatSum, totalWieght, res),
                totalWieght,
                res
              ) /
                1.75) *
                100
            ),
            cholesterol: Math.ceil(
              (toFixed(
                (getTotalValue(cholesterolSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                300) *
                100
            ),
            Sodium: Math.ceil(
              (toFixed(
                (getTotalValue(SodiumSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                2300) *
                100
            ),
            vitaminD: Math.ceil(
              (toFixed(
                (getTotalValue(vitaminDSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                20) *
                100
            ),
            calcium: Math.ceil(
              (toFixed(
                (getTotalValue(calciumSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                1300) *
                100
            ),
            totalCarbohydrate: Math.ceil(
              (toFixed(
                (getTotalValue(totalCarbohydrateSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                275) *
                100
            ),
            dietaryFiber: Math.ceil(
              (toFixed(
                (getTotalValue(dietaryFiberSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                28) *
                100
            ),
            addedSugars: Math.ceil(
              (toFixed(
                (getTotalValue(addedSugarsSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                50) *
                100
            ),
            protein: Math.ceil(
              (toFixed(
                (getTotalValue(proteinSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                50) *
                100
            ),
            iron: Math.ceil(
              (toFixed(
                (getTotalValue(ironSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                18) *
                100
            ),
            potassium: Math.ceil(
              (toFixed(
                (getTotalValue(potassiumSum, totalWieght, res) /
                  recipeItem.serving_size) *
                  100
              ) /
                4700) *
                100
            ),
          },
        };

        setRecipe(recipeItem);
        setRecipePer100Gram(recipeItemPer100Gram);
      } catch (e) {}
    };

    if (props.recipeId !== undefined || props.recipeId !== "")
      getRecipeDetails();
    // console.log(recipe);
  }, [props.recipeId]);

  const onClose = async () => {
    try {
      props.onClose();
      setRecipe({});
    } catch (err) {}
  };

  const downloadAsPDF = () => {
    const input = document.getElementById("nutriTable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: direction === 1 ? "p" : "l", // landscape
        unit: "pt", // points, pixels won't work properly
        format: [canvas.width, canvas.height], // set needed dimensions for any element
      });
      pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
      // pdf.output('dataurlnewwindow');
      pdf.save(recipe.name + ".pdf");
    });
  };

  var componentRef = useRef(null);
  return (
    <>
      <Modal
        open={props.open !== false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={onClose}
      >
        <Fade in={props.open !== false}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                widht: "100%",
                display: { lg: "flex", xl: "flex", s: "none", xs: "none" },
              }}
            >
              <QRCode
                value={clientBaseUrl + "label-generator/" + props.recipeId}
                size={64}
                style={{ position: "absolute", top: "50px" }}
              />
              <IconButton
                variant="outlined"
                color="error"
                onClick={(e) => {
                  props.onClose();
                }}
                sx={{
                  marginRight: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",

                widht: "100%",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="serving-selector"
                  id="serving-selector"
                  onChange={handleChange}
                  defaultValue={2}
                >
                  <MenuItem key={2} value={2}>
                    Per Serving
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Per 100 Gram
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    Both
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                  display: { lg: "flex", xl: "flex", s: "none", xs: "none" },
                }}
                size="small"
              >
                <Select
                  labelId="direction-selector"
                  id="direction-selector"
                  onChange={handleDirectionChange}
                  defaultValue={1}
                >
                  <MenuItem key={1} value={1}>
                    Vertical
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Horizontal
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="language-selector"
                  id="language-selector"
                  defaultValue={"en"}
                >
                  {Object.keys(lngs).map((lng, ii) => (
                    <MenuItem
                      value={lng}
                      key={ii}
                      onClick={() => {
                        i18n.changeLanguage(lng);
                        // console.log(i18n.language);
                      }}
                    >
                      {lngs[lng].nativeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button onClick={downloadAsPDF}>Save As PDF</Button>
              <div>
                <ReactToPrint
                  trigger={() => <Button>Print</Button>}
                  content={() => componentRef}
                  pageStyle={
                    direction === 1
                      ? "@page { size: 260px 670px !important } "
                      : "@page { size: 700px 250px !important}"
                  }
                />
              </div>
            </Box>

            {direction === 1 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                pt={5}
              >
                <div
                  className="container-pad-vertical "
                  dir={i18n.language === "ar" ? "rtl" : ""}
                  id="nutriTable"
                  ref={(el) => {
                    componentRef = el;
                  }}
                >
                  <div>
                    <p className="p-facts-style">{t("Nutrition Facts")}</p>
                    <div className="serving-qty">
                      <span className="text-light mb5 spanny">
                        {recipe.name}
                      </span>
                      <div className="serving-val">
                        <strong>Serving size</strong>
                        <strong className="value">
                          (
                          {servingSize !== 1
                            ? recipe.serving_size
                            : recipePer100Gram.serving_size}
                          g)
                        </strong>
                      </div>
                    </div>
                    <div className="nutri-block">
                      <div className="nutri-value">
                        <div className="heading">
                          <span className="spanny">
                            {t("Amount Per Serving")}
                          </span>
                          <strong>{t("calories")}</strong>
                        </div>
                        <strong className="value">
                          {servingSize !== 1
                            ? recipe.calories
                            : recipePer100Gram.calories}
                        </strong>
                      </div>
                    </div>
                    <div className="nutri-fact-table">
                      <div className="nutri-fact-list">
                        <div className="nutri-list-item">
                          <strong className="w--40"></strong>
                          <strong className="w--40 text-right">
                            Per
                            {servingSize !== 1
                              ? recipe.serving_size
                              : recipePer100Gram.serving_size}
                            g
                          </strong>
                          {servingSize === 3 && (
                            <strong className="w--40 text-right">
                              Per
                              {recipePer100Gram.serving_size} g
                            </strong>
                          )}
                          <strong className="w--20 text-right">
                            {t("Dv")}
                            <br />
                          </strong>
                        </div>
                        <div className="nutri-list-item">
                          <strong className="w--40">{t("totalfat")} </strong>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.total_fat
                              : recipePer100Gram.total_fat}{" "}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.total_fat} g
                            </span>
                          )}
                          <strong className="w--20 text-right">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.total_fat
                              : recipePer100Gram.recipeItemDV?.total_fat}
                            %
                          </strong>
                        </div>
                        <div className="nutri-inner-list">
                          <div className="list-sub-item">
                            <span className="w--40 spanny">
                              {t("SaturatedFat")}
                            </span>
                            <span className="w--40 text-right spanny">
                              {servingSize !== 1
                                ? recipe.saturatedFat
                                : recipePer100Gram.saturatedFat}{" "}
                              g
                            </span>
                            {servingSize === 3 && (
                              <span className="w--40 text-right spanny">
                                {recipePer100Gram.saturatedFat} g
                              </span>
                            )}
                            <strong className="w--20 text-right">
                              {servingSize !== 1
                                ? recipe.recipeItemDV?.saturatedFat
                                : recipePer100Gram.recipeItemDV?.saturatedFat}
                              %
                            </strong>
                          </div>
                          <div className="list-sub-item">
                            <span className="w--40 spanny">
                              {t("TransFat")}
                            </span>
                            <span className="w--40 text-right spanny">
                              {servingSize !== 1
                                ? recipe.transFat
                                : recipePer100Gram.transFat}{" "}
                              g
                            </span>
                            {servingSize === 3 && (
                              <span className="w--40 text-right spanny">
                                {recipePer100Gram.transFat} g
                              </span>
                            )}
                            <strong className="w--20 text-right"></strong>
                          </div>
                        </div>
                      </div>
                      <div className="nutri-list-item">
                        <strong className="w--40">{t("cholesterol")} </strong>
                        <span className="w--40 text-right spanny">
                          {servingSize !== 1
                            ? recipe.cholesterol
                            : recipePer100Gram.cholesterol}{" "}
                          g
                        </span>
                        {servingSize === 3 && (
                          <span className="w--40 text-right spanny">
                            {recipePer100Gram.cholesterol} g
                          </span>
                        )}
                        <strong className="w--20 text-right">
                          {servingSize !== 1
                            ? recipe.recipeItemDV?.cholesterol
                            : recipePer100Gram.recipeItemDV?.cholesterol}
                          %
                        </strong>
                      </div>
                      <div className="nutri-list-item">
                        <strong className="w--40">{t("Sodium")} </strong>
                        <span className="w--40 text-right spanny">
                          {servingSize !== 1
                            ? recipe.Sodium
                            : recipePer100Gram.Sodium}{" "}
                          mg
                        </span>

                        {servingSize === 3 && (
                          <span className="w--40 text-right spanny">
                            {recipePer100Gram.Sodium} mg
                          </span>
                        )}
                        <strong className="w--20 text-right">
                          {servingSize !== 1
                            ? recipe.recipeItemDV?.Sodium
                            : recipePer100Gram.recipeItemDV?.Sodium}
                          %
                        </strong>
                      </div>
                      <div className="nutri-fact-list">
                        <div className="nutri-list-item">
                          <strong className="w--40">
                            {" "}
                            {t("totalCarbohydrate")}{" "}
                          </strong>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.totalCarbohydrate
                              : recipePer100Gram.totalCarbohydrate}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.totalCarbohydrate} g
                            </span>
                          )}
                          <strong className="w--20 text-right">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.totalCarbohydrate
                              : recipePer100Gram.recipeItemDV
                                  ?.totalCarbohydrate}
                            %
                          </strong>
                        </div>
                        <div className="nutri-inner-list">
                          <div className="list-sub-item">
                            <span className="w--40 spanny">
                              {t("dietaryFiber")}
                            </span>
                            <span className="w--40 text-right spanny">
                              {servingSize !== 1
                                ? recipe.dietaryFiber
                                : recipePer100Gram.dietaryFiber}{" "}
                              g
                            </span>
                            {servingSize === 3 && (
                              <span className="w--40 text-right spanny">
                                {recipePer100Gram.dietaryFiber} g
                              </span>
                            )}
                            <strong className="w--20 text-right">
                              {servingSize !== 1
                                ? recipe.recipeItemDV?.dietaryFiber
                                : recipePer100Gram.recipeItemDV?.dietaryFiber}
                              %
                            </strong>
                          </div>
                          <div className="list-sub-item">
                            <span className="w--40 spanny">
                              {" "}
                              {t("TotalSugars")}
                            </span>
                            <span className="w--40 text-right spanny">
                              {servingSize !== 1
                                ? recipe.totalSugars
                                : recipePer100Gram.totalSugars}{" "}
                              g
                            </span>
                            {servingSize === 3 && (
                              <span className="w--40 text-right spanny">
                                {recipePer100Gram.totalSugars} g
                              </span>
                            )}
                            <strong className="w--20 text-right"></strong>
                          </div>
                          <div className="list-sub-item">
                            <span className="w--40 pl-10 spanny">
                              {" "}
                              {t("addedSugars")}
                            </span>
                            <span className="w--40 text-right spanny">
                              {servingSize !== 1
                                ? recipe.addedSugars
                                : recipePer100Gram.addedSugars}{" "}
                              g
                            </span>
                            {servingSize === 3 && (
                              <span className="w--40 text-right spanny">
                                {recipePer100Gram.addedSugars} g
                              </span>
                            )}
                            <strong className="w--20 text-right">
                              {servingSize !== 1
                                ? recipe.recipeItemDV?.addedSugars
                                : recipePer100Gram.recipeItemDV?.addedSugars}
                              %
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="nutri-fact-list">
                        <div className="nutri-list-item">
                          <strong className="w--40">{t("protein")} </strong>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.protein
                              : recipePer100Gram.protein}{" "}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.protein} g
                            </span>
                          )}
                          <strong className="w--20 text-right">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.protein
                              : recipePer100Gram.recipeItemDV?.protein}
                            %
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="vitamin-block">
                      <div className="vita-list">
                        <div className="vita-item">
                          <span className="w--40 spanny">{t("vitaminD")} </span>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.vitaminD
                              : recipePer100Gram.vitaminD}{" "}
                            mcg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.vitaminD} mcg
                            </span>
                          )}
                          <span className="w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.vitaminD
                              : recipePer100Gram.recipeItemDV?.vitaminD}
                            %
                          </span>
                        </div>
                        <div className="vita-item">
                          <span className="w--40 spanny">{t("calcium")}</span>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.calcium
                              : recipePer100Gram.calcium}{" "}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.calcium} mg
                            </span>
                          )}
                          <span className="w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.calcium
                              : recipePer100Gram.recipeItemDV?.calcium}
                            %
                          </span>
                        </div>
                        <div className="vita-item">
                          <span className="w--40 spanny">{t("iron")}</span>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.iron
                              : recipePer100Gram.iron}{" "}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.iron} mg
                            </span>
                          )}
                          <span className="w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.iron
                              : recipePer100Gram.recipeItemDV?.iron}
                            %
                          </span>
                        </div>
                        <div className="vita-item">
                          <span className="w--40 spanny">{t("potassium")}</span>
                          <span className="w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.potassium
                              : recipePer100Gram.potassium}{" "}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.potassium} mg
                            </span>
                          )}
                          <span className="w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.potassium
                              : recipePer100Gram.recipeItemDV?.potassium}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="nutrinote">
                      <p>{t("DailyValue")}</p>
                    </div>
                  </div>
                </div>
              </Box>
            ) : (
              <Box className="container" pt={25}>
                <div
                  className="container-pad-horizontal"
                  dir={i18n.language === "ar" ? "rtl" : ""}
                  id="nutriTable"
                  ref={(el) => {
                    componentRef = el;
                  }}
                >
                  <div className="label-container">
                    <div className="label-cols">
                      <h3 className="mb5">{t("Nutrition Facts")}</h3>
                      <span className="text-light mb5 spanny">
                        {recipe.name}
                      </span>
                      <div className="mb5">
                        <span className="text-bold">1</span>
                        <span className="text-bold">
                          (
                          {servingSize !== 1
                            ? recipe.serving_size
                            : recipePer100Gram.serving_size}
                          g)
                        </span>
                      </div>
                      <div className="hair-line-seprator"></div>
                      <div className="flex-row">
                        <div className="flex-column">
                          <span
                            className="text-bold text-lg spanny"
                            sx={{ marginBottom: "3px" }}
                          >
                            {t("calories")}
                          </span>

                          <span className="text-bold text-lg spanny">
                            {t("perserving")}
                          </span>
                        </div>
                        <span className="text-bold text-xlg spanny">
                          {servingSize !== 1
                            ? recipe.calories
                            : recipePer100Gram.calories}
                        </span>
                      </div>
                    </div>
                    <div className="label-cols">
                      <div className="nutrition-block">
                        <div className="row-item">
                          <span className="text-bold w--40 spanny"></span>

                          <span className="text-bold w--40 text-right label-head spanny">
                            Per
                            {servingSize !== 1
                              ? recipe.serving_size
                              : recipePer100Gram.serving_size}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="text-bold w--40 text-right label-head spanny">
                              Per
                              {recipePer100Gram.serving_size} g
                            </span>
                          )}
                          <span className="text-bold w--40 text-right label-head spanny">
                            % DV<sup>*</sup>
                            <br />
                          </span>
                        </div>
                        <div className="seprator mb5"></div>
                        <div className="row-item border-btm">
                          <span className="text-bold w--40 spanny">
                            {t("totalfat")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.total_fat
                              : recipePer100Gram.total_fat}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.total_fat} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.total_fat
                              : recipePer100Gram.recipeItemDV?.total_fat}
                            %
                          </span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-light text-sm w--40 spanny">
                            {t("SaturatedFat")}
                          </span>

                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.saturatedFat
                              : recipePer100Gram.saturatedFat}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.saturatedFat} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.saturatedFat
                              : recipePer100Gram.recipeItemDV?.saturatedFat}
                            %
                          </span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-light text-sm w--40 spanny">
                            {t("TransFat")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.transFat
                              : recipePer100Gram.transFat}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.transFat} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny"></span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-bold w--40 spanny">
                            {" "}
                            {t("cholesterol")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.cholesterol
                              : recipePer100Gram.cholesterol}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.cholesterol} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.cholesterol
                              : recipePer100Gram.recipeItemDV?.cholesterol}
                            %
                          </span>
                        </div>
                        <div className="row-item">
                          <span className="text-bold w--40 spanny">
                            {" "}
                            {t("Sodium")}{" "}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.Sodium
                              : recipePer100Gram.Sodium}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.Sodium} mg
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.Sodium
                              : recipePer100Gram.recipeItemDV?.Sodium}
                            %
                          </span>
                        </div>
                        <div className="seprator mb5"></div>
                        <div className="row-item">
                          <span className="text-light w--40 spanny">
                            {t("vitaminD")}{" "}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.vitaminD
                              : recipePer100Gram.vitaminD}
                            mcg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.vitaminD} mcg
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.vitaminD
                              : recipePer100Gram.recipeItemDV?.vitaminD}
                            %
                          </span>
                        </div>
                        <div className="row-item">
                          <span className="text-light w--40 spanny">
                            {t("calcium")}{" "}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.calcium
                              : recipePer100Gram.calcium}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.calcium} mg
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.calcium
                              : recipePer100Gram.recipeItemDV?.calcium}
                            %
                          </span>
                        </div>
                      </div>

                      <div className="nutrition-block">
                        <div className="row-item">
                          <span className="text-bold w--40 spanny"></span>
                          <span className="text-bold w--40 text-right label-head spanny">
                            Per
                            {servingSize !== 1
                              ? recipe.serving_size
                              : recipePer100Gram.serving_size}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="text-bold w--40 text-right label-head spanny">
                              Per
                              {recipePer100Gram.serving_size} g
                            </span>
                          )}
                          <span className="text-bold w--40 text-right label-head spanny">
                            % DV<sup>*</sup>
                            <br />
                          </span>
                        </div>
                        <div className="seprator mb5"></div>
                        <div className="row-item border-btm">
                          <span className="text-bold w--40 spanny">
                            {t("totalCarbohydrate")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.totalCarbohydrate
                              : recipePer100Gram.totalCarbohydrate}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.totalCarbohydrate} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.totalCarbohydrate
                              : recipePer100Gram.recipeItemDV
                                  ?.totalCarbohydrate}
                            %
                          </span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-light text-sm w--40 spanny">
                            {t("dietaryFiber")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.dietaryFiber
                              : recipePer100Gram.dietaryFiber}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.dietaryFiber} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.dietaryFiber
                              : recipePer100Gram.recipeItemDV?.dietaryFiber}
                            %
                          </span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-light text-sm w--40 spanny">
                            {t("TotalSugars")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.totalSugars
                              : recipePer100Gram.totalSugars}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.totalSugars} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny"></span>
                        </div>
                        <div className="row-item border-btm">
                          <span className="text-light text-sm w--40 pl-10">
                            {t("addedSugars")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.addedSugars
                              : recipePer100Gram.addedSugars}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.addedSugars} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.addedSugars
                              : recipePer100Gram.recipeItemDV?.addedSugars}
                            %
                          </span>
                        </div>
                        <div className="row-item">
                          <span className="text-bold w--40 spanny">
                            {" "}
                            {t("protein")}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.protein
                              : recipePer100Gram.protein}
                            g
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.protein} g
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.protein
                              : recipePer100Gram.recipeItemDV?.protein}
                            %
                          </span>
                        </div>
                        <div className="seprator mb5"></div>
                        <div className="row-item">
                          <span className="text-light w--40 spanny">
                            {t("iron")}{" "}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.iron
                              : recipePer100Gram.iron}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.iron} mg
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.iron
                              : recipePer100Gram.recipeItemDV?.iron}
                            %
                          </span>
                        </div>
                        <div className="row-item">
                          <span className="text-light w--40 spanny">
                            {t("potassium")}{" "}
                          </span>
                          <span className="text-light w--40 text-right spanny">
                            {servingSize !== 1
                              ? recipe.potassium
                              : recipePer100Gram.potassium}
                            mg
                          </span>
                          {servingSize === 3 && (
                            <span className="w--40 text-right spanny">
                              {recipePer100Gram.potassium} mg
                            </span>
                          )}
                          <span className="text-bold w--20 text-right spanny">
                            {" "}
                            {servingSize !== 1 && servingSize !== 3
                              ? recipe.recipeItemDV?.potassium
                              : recipePer100Gram.recipeItemDV?.potassium}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="label-cols">
                      <p>
                        <sup>*</sup> {t("DailyValue")}
                      </p>
                    </div>
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NutritionsFactsLabelVertical;
