import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./css/custom.scrollbar.css";
import CssBaseLine from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import AuthLayout from "./components/layout/AuthLayout";
import Boards from "./pages/Boards";
import Board from "./pages/Board";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Costs from "./pages/Costs";
import AppHome from "./pages/AppHome";
import NutritionsFactsLabelVertical from "./components/Labels/NutritionsFactsLabelVertical";
import LabelWithIndicators from "./components/Labels/LabelWithIndicators";
import Home from "./pages/Home";
import AddNewNutrition from "./pages/AddNewNutrition";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      // primary: {
      //   main: "#42a5f5",
      // },
      // secondary: {
      //   main: "#ba68c8",
      // },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseLine />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route index path="/" element={<Home />} />

          <Route path="signup" element={<Signup />} />
          <Route path="addnew" element={<AddNewNutrition />} />
          <Route path="*" element={<PageNotFound />} />

          <Route path="/app" element={<AppLayout />}>
            <Route path="dashboard" element={<AppHome />} />
            <Route element={<Boards />} />
            <Route path="boards" element={<Boards />} />
            <Route path="boards/:boardId" element={<Board />} />
            <Route path="profile" element={<Profile />} />
            <Route path="costs" element={<Costs />} />
          </Route>
          <Route
            path="/label-generator/:recipeId"
            element={<NutritionsFactsLabelVertical />}
          ></Route>
          <Route
            path="/label-generator/indicators/:recipeId"
            element={<LabelWithIndicators />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
