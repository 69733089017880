import axiosClient from "./axiosClient";

const foodApi = {
  searchItem: (page, limit, query, params) =>
    axiosClient.get(
      `foods/foodlist?page=${page}&limit=${limit}&query=${query}`,
      params
    ),
  getFoodItem: (fdcId, params) =>
    axiosClient.get(`foods/fooditem?fdcId=${fdcId}`, params),
};

export default foodApi;
