import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, formLabelClasses } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-toastify";
import { currency, toastSuccessOptions } from "../utils/constVals";
import { toastErrorOptions } from "../utils/constVals";

import ingredientApi from "../api/ingredientApi";

const Costs = (props) => {
  const user = useSelector((state) => state.user.value);

  const navigate = useNavigate();
  const [ingredientsRows, setIngredientsRows] = useState([]);

  const [dataGridRows, setDataGridRows] = useState([]);
  const [ingredientsApiDetails, setIngredientsApiDetails] = useState([]);
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const headers = [
    {
      field: "0",
      headerName: "S/N",
      width: 20,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "1",
      headerName: "Name",
      flex: 1,
      minWidth: 550,
      headerAlign: "center",

      align: "center",
    },
    {
      field: "2",
      headerName: "Cost",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
      editable: true,
    },
    {
      field: "3",
      headerName: "Grams",
      flex: 1,
      headerAlign: "center",
      editable: true,
      type: "number",

      align: "center",
    },
    {
      field: "4",
      headerName: "Yield",
      flex: 1,
      headerAlign: "center",
      editable: true,
      type: "number",

      align: "center",
    },
    {
      field: "5",
      headerName: "Per Gram Cost",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      valueGetter: (params) =>
        (params.row["2"] / params.row["3"]) *
        (100 / (params.row["4"] === 0 ? 100 : params.row["4"])),
    },
    {
      field: "action",
      align: "center",
      headerAlign: "center",

      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation();

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          // res = ingredientIdFromHiddenField
          // props.deleteOneIngredient(ingredientIdFromHiddenField)
          try {
            var index = JSON.stringify(thisRow["0"], null, 4);
            var _cost = JSON.stringify(thisRow["2"], null, 4);
            var _grams = JSON.stringify(thisRow["3"], null, 4);
            var _yield = JSON.stringify(thisRow["4"], null, 4);
            index--;
            const recipesIngredients = await ingredientApi.updateCost(
              ingredientsApiDetails[index].boardId,
              ingredientsApiDetails[index].recipe,
              ingredientsApiDetails[index]._id,
              user.id,
              {
                _cost,
                _cost_per_gram: _grams,
                _cost_yield: _yield,
              }
            );
            //  console.log(ingredientsApiDetails);
            toast.success("Cost Updated!", toastSuccessOptions);
          } catch (err) {
            console.log(err);

            toast.success(err, toastErrorOptions);
          }
        };

        return <Button onClick={onClick}>Update</Button>;
      },
    },
  ];

  useEffect(() => {
    const addNewIngredientsRows = (row) => {
      if (ingredientsRows.length !== 0) setIngredientsRows([...row]);
      else setIngredientsRows([...ingredientsRows, ...row]);
    };
    // console.log(user)

    const getThem = async () => {
      try {
        const recipesIngredientsArray = await ingredientApi.getCosts(user.id);
        const recipesIngredients = recipesIngredientsArray[0];
        setIngredientsApiDetails(recipesIngredients);
        // console.log(recipeIngredients)
        // nutritionValuesHeaders.slice(1)

        if (recipesIngredients !== undefined) {
          var ingredientsRows = [];

          for (let index = 0; index < recipesIngredients.length; index++) {
            //Ingredeitns Rows
            let p = recipesIngredients[index];
            const recipeDetails = [
              p.name,
              p.cost,
              p.cost_per_gram,
              p.cost_yield,
              0,
            ];
            ingredientsRows.push(recipeDetails);
          }

          addNewIngredientsRows(ingredientsRows);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getThem();
  }, []);

  //create data rows
  useEffect(() => {
    var finalAllRows = [];
    var rowId = 0;
    ingredientsRows.map((row, index) => {
      var dd = { id: rowId++ };
      row.map((element, cellIndex) => {
        dd[cellIndex + 1] = element;
      });
      dd[0] = rowId;

      finalAllRows.push(dd);
    });
    setDataGridRows(finalAllRows);
    // console.log(finalAllRows)
  }, [ingredientsRows]);

  var CustomFooterTotalComponent = () => {
    //<10 to show the custom footer only on the ingredients table
    return <Box sx={{ padding: "5px", display: "flex" }}></Box>;
  };

  return (
    <>
      <DataGrid
        rows={dataGridRows}
        columns={headers}
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: CircularProgress,
          Footer: CustomFooterTotalComponent,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowClassName={(params) =>
          "bg-[#8080804d] transition duration-300 hover:bg-[#8080806e] font-[500]"
        }
        sx={{
          boxShadow: 4,
          ".MuiDataGrid-columnSeparator": {},
          "&.MuiDataGrid-root": {
            border: "none",
          },
        }}
        density="comfortable"
      />
    </>
  );
};

export default Costs;
