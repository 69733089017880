import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import { setBoards } from "../redux/features/boardSlice";
import boardApi from "../api/boardApi";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastErrorOptions } from "../utils/constVals";

const Boards = () => {
  const boards = useSelector((state) => state.board.value);
  const { boardId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const createBoard = async () => {
    try {
      const res = await boardApi.create();
      dispatch(setBoards([res]));
      navigate(`/app/boards/${res.id}`);
    } catch (err) {
      toast.success(err, toastErrorOptions);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (boards.length > 0 && boardId === undefined) {
      navigate(`/app/boards/${boards[0].id}`);
    }
  }, [boards, boardId, navigate]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingButton
        variant="outlined"
        color="success"
        onClick={createBoard}
        loading={loading}
      >
        CLick here to create your first Category
      </LoadingButton>
    </Box>
  );
};

export default Boards;
