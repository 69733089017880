import axios from "axios";

import axiosClient, { assetBaseUrl } from "./axiosClient";
const getToken = () => localStorage.getItem("token");

const authApi = {
  signup: (params) => axiosClient.post("auth/signup", params),
  login: (params) => axiosClient.post("auth/login", params),
  resetpassword: (params) => axiosClient.post("auth/resetpassword", params),
  verifyToken: () => axiosClient.post("auth/verify-token"),
  getOne: (id) => axiosClient.get(`boards/${id}`),
  updatePhoto: async (formData) => {
    try {
      return await axios({
        method: "post",
        url: assetBaseUrl + "api/v1/auth/profile",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",

          authorization: `Bearer ${getToken()}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  getPhoto: (params) =>
    axiosClient.get(assetBaseUrl + "api/v1/auth/profile", params),
};
export default authApi;
