import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import {
  Backdrop,
  Fade,
  Modal,
  Box,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import { lngs } from "../utils/constVals";
import assets from "../assets";
import { toFixed } from "../utils/utils";
import { assetBaseUrl, baseUrl } from "../api/axiosClient";
import recipeApi from "../api/recipeApi";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "450px",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "700px",

  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
};

const RecipeCartModal = (props) => {
  const user = useSelector((state) => state.user.value);

  const [recipe, setRecipe] = useState({});
  const { recipeId } = useParams(undefined);
  const [curretnRecipe, setCurrentRecipe] = useState({});
  const boardId = props.boardId;
  var componentRefCart = useRef(null);
  const [height, setHeight] = useState(0);
  const refe = useRef(null);
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecipe(props.recipeId);
  }, [props.recipeId]);

  const getTotalValue = (val, totalWieght, res) => {
    return toFixed(
      ((val / totalWieght) * res[0].recipe.per_serving_weight * totalWieght) /
        res[0].recipe.total_cooked_wieght
    );
  };

  useEffect(() => {
    const getRecipeDetails = async () => {
      if (
        (props.recipeId === undefined || props.recipeId === "") &&
        recipeId === undefined
      )
        return;
      setLoading(true);
      try {
        var url = "";
        if (recipeId !== undefined) {
          url = recipeId.id;
        } else url = props.recipeId.id;
        const { data: res } = await axios.get(
          baseUrl + "ingredients/getall/" + url
        );

        let caloriesSum = 0;
        let saturatedFatSum = 0;
        let totalFatSum = 0;
        let ProteinSum = 0;
        let totalCarbsSum = 0;
        let totalWieght = 0;

        res.forEach((key, value) => {
          totalWieght +=
            parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
            parseFloat(key.qty);

          key.nutriFacts.foodNutrients.map((singleRecipeelm, index) => {
            let currentNutrName = singleRecipeelm.nutrient.name;
            let currentAmount =
              (singleRecipeelm.amount / 100) *
              (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                parseFloat(key.qty));

            if (
              currentNutrName === "Energy" &&
              singleRecipeelm.nutrient.unitName === "kcal"
            ) {
              caloriesSum += currentAmount;
            }
            if (
              currentNutrName.includes("(fat)") ||
              currentNutrName.includes("fatty")
            ) {
              totalFatSum += currentAmount;
            }

            // if (currentNutrName === "Fatty acids, total saturated") {
            //   saturatedFatSum += currentAmount;
            // }

            if (currentNutrName === "Protein") {
              ProteinSum += currentAmount;
            }

            if (currentNutrName === "Carbohydrate, by difference") {
              totalCarbsSum += currentAmount;
            }
          });
        });
        const recipeItem = {
          name: res[0].recipe.title,
          id: res[0].recipe.id,
          serving_size: res[0].recipe.per_serving_weight,
          calories: getTotalValue(caloriesSum, totalWieght, res),
          totalCarbs: getTotalValue(totalCarbsSum, totalWieght, res),
          Protein: getTotalValue(ProteinSum, totalWieght, res),
          total_fat: getTotalValue(totalFatSum, totalWieght, res),
          // saturatedFat: getTotalValue(saturatedFatSum, totalWieght, res),
        };

        setCurrentRecipe(recipeItem);
      } catch (e) {}
    };

    getRecipeDetails();
    const getProfileImage = async () => {
      if (props.recipeId !== undefined)
        try {
          const recipePhoto = await recipeApi.getPhoto(
            boardId,
            props.recipeId.id
          );
          if (recipePhoto !== "") {
            setPhoto(assetBaseUrl + recipePhoto);
          }
        } catch (err) {
          console.log(err);
        }
      setLoading(false);
    };
    getProfileImage();
  }, [props.recipeId]);

  useEffect(() => {
    setHeight(refe?.current?.clientHeight);
  }, []);

  const onClose = async () => {
    try {
      props.onClose();
      setCurrentRecipe({});
    } catch (err) {}
  };

  return (
    <>
      <Modal
        open={props.open !== false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={onClose}
      >
        <Fade in={props.open !== false}>
          <Box sx={modalStyle}>
            {loading ? (
              <Stack spacing={3}>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={100} height={100} />
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"20vh"}
                />
                <Skeleton variant="rounded" width={"100%"} height={"20vh"} />
              </Stack>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    widht: "100%",
                  }}
                >
                  <IconButton
                    variant="outlined"
                    color="error"
                    onClick={(e) => {
                      props.onClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => componentRefCart}
                    pageStyle={
                      // "@page { size: 310px " + height * 2 + "px !important }"
                      "@page { size: 310px " + 600 + "px !important }"
                    }
                  />
                </Box>
                <div id="recipeCard" ref={refe}>
                  <Card
                    sx={{
                      maxWidth: 445,
                      boxShadow: 10,
                    }}
                    ref={(el) => {
                      componentRefCart = el;
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: "180px",
                      }}
                      image={photo}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          {recipe?.title}
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div">
                          ({curretnRecipe?.serving_size}g)
                        </Typography>
                      </Box>
                      <Box>
                        <Divider sx={{ marginTop: 1 }} />

                        <Typography
                          gutterBottom
                          variant="body1"
                          component="div"
                        >
                          Ingredients:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {recipe?.ingredients?.map((key, value) => {
                            if (value !== recipe.ingredients.length - 1)
                              return key.name + ", ";
                            else return key.name + ".";
                          })}
                        </Typography>
                      </Box>
                      <Divider sx={{ marginTop: 1 }} />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 1,
                        }}
                      >
                        <Divider />
                        <CircleComponent
                          curretnRecipe={curretnRecipe}
                          type="Calories"
                          value={curretnRecipe.calories}
                        />
                        <CircleComponent
                          curretnRecipe={curretnRecipe}
                          type="Protein"
                          value={curretnRecipe.Protein}
                        />
                        <CircleComponent
                          curretnRecipe={curretnRecipe}
                          type="Carbs"
                          value={curretnRecipe.totalCarbs}
                        />
                        <CircleComponent
                          curretnRecipe={curretnRecipe}
                          type="Fat"
                          value={curretnRecipe.total_fat}
                        />
                      </Box>
                      <Divider sx={{ marginTop: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 1,
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {user.website}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

const CircleComponent = ({ curretnRecipe, type, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 1,
      }}
    >
      <svg viewBox="0 0 75 75" width="75" height="75">
        <ellipse
          style={{ stroke: " rgb(0, 0, 0)", fill: "none" }}
          cx="37.5"
          cy="37.5"
          rx="37.5"
          ry="37.5"
        ></ellipse>
        <text
          style={{
            whiteSpace: "pre",
            fill: "rgb(51, 51, 51)",
            fontSize: "2.3px",
          }}
          x={value < 99 ? "13.298" : "11.898"}
          y={type === "Calories" ? "14.631" : "15.331"}
          transform="matrix(7.249939, 0, 0, 9.544008, -75.06675, -99.196877)"
        >
          {~~value}
          {type !== "Calories" ? "g" : ""}
        </text>
        <text
          style={{
            whiteSpace: "pre",
            fill: "rgb(51, 51, 51)",
            fontSize: "3.1px",
          }}
          x="11.898"
          y="14.331"
          transform="matrix(3.945326, 0, 0, 5.130635, -20.570055, -6.79272)"
        >
          {type === "Calories" ? "Kcal" : ""}
        </text>
      </svg>
      <Typography>{type}</Typography>
    </Box>
  );
};

export default RecipeCartModal;
