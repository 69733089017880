import Card from "@mui/material/Card";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Typography from "@mui/material/Typography";

const TotalRecipesCost = ({ totalRecipesCost = 0 }) => {
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  const goTo = (link) => {
    navigate(link);
  };

  useEffect(() => {
    if (counter < totalRecipesCost) {
      const timeout = setTimeout(() => {
        if (totalRecipesCost - counter > 100) setCounter(counter + 100);
        else setCounter(counter + 1);
      }, 0.001);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [counter, totalRecipesCost]);

  return (
    <>
      <Card
        sx={{
          background: "#80cd29",
          color: "white",
          borderRadius: "0 20px 0 20px !important",
          minHeight: 200,
        }}
        className="shadow-2xl shadow-lime-700  drop-shadow-2xl"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            widht: "100%",
          }}
        >
          <CardActions
            sx={{
              fontSize: 40,
              backgroundColor: "#0000002e",
              borderRadius: "0px 20px 0px 20px !important",
              position: "absolute",
            }}
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                goTo("/app/costs");
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                  color: "gold",
                  transition: " 0.3s",
                },
              }}
            ></RemoveRedEyeOutlinedIcon>
          </CardActions>
        </Box>
        <br />
        <CardContent
          sx={{
            marginLeft: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">{counter}.00 </Typography>
            <p className="ml-2 ">AED</p>
            <ListAltOutlinedIcon
              onClick={() => {
                goTo("/app/boards");
              }}
              sx={{
                fontSize: 40,
                backgroundColor: "#0000002e",
                borderRadius: "10px 10px 10px 10px ",
                padding: 1,
                marginLeft: 2,
                ":hover": {
                  cursor: "pointer",
                  color: "gold",
                  transition: " 0.3s",
                },
              }}
            ></ListAltOutlinedIcon>
          </Box>
          <Typography sx={{ color: "#ffffff99" }}>
            Total Recipes Cost
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default TotalRecipesCost;
