import axios from "axios";
import axiosClient, { assetBaseUrl, baseUrl } from "./axiosClient";
const getToken = () => localStorage.getItem("token");

const recipeApi = {
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/recipes`, params),
  duplicate: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/recipes/duplicate`, params),
  updatePosition: (boardId, params) =>
    axiosClient.put(`boards/${boardId}/recipes/update-position`, params),
  delete: (boardId, recipeId) =>
    axiosClient.delete(`boards/${boardId}/recipes/${recipeId}`),
  update: (boardId, recipeId, params) =>
    axiosClient.put(`boards/${boardId}/recipes/${recipeId}`, params),
  getRecipeIngredients: (boardId, recipeId, params) =>
    axiosClient.get(`boards/${boardId}/recipes/${recipeId}`, params),
  updatePhoto: async (formData, boardId, recipeId) => {
    try {
      return await axios({
        method: "post",
        url: baseUrl + `boards/${boardId}/recipes/${recipeId}/photo`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",

          authorization: `Bearer ${getToken()}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  getPhoto: (boardId, recipeId, params) =>
    axiosClient.get(
      baseUrl + `boards/${boardId}/recipes/${recipeId}/photo`,
      params
    ),
};

export default recipeApi;
