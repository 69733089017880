import { useEffect } from "react";
import ApexCharts from "apexcharts";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { toFixed } from "../../utils/utils";

const ColumnLineChart = ({ recipesDetails }) => {
  var options = {
    series: [
      {
        name: "",
        type: "column",
        data: [],
      },
      {
        name: "",
        type: "line",
        data: [],
      },
    ],
    chart: {
      type: "line",
      width: "100%",
      height: "90%",
    },
    stroke: {
      width: [0, 4],
    },

    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    // labels: [],
    colors: ["#80cd29", "#121212"],
    plotOptions: {
      bar: {
        columnWidth: "3%",
        distributed: true,
      },
    },

    labels: [],

    xaxis: {
      type: "categories",
    },
    yaxis: [
      {
        title: {
          text: "Calories",
        },
      },
      {
        opposite: true,
        title: {
          text: "Calories",
        },
      },
    ],
    // noData: {
    //   text: "Loading...",
    //   align: "center",
    //   verticalAlign: "middle",
    //   offsetX: 0,
    //   offsetY: 0,
    //   style: {
    //     color: "#000000",
    //     fontSize: "54px",
    //     fontFamily: "Roboto",
    //   },
    // },
  };

  useEffect(() => {
    const ordersMap = new Map();
    var chart = new ApexCharts(
      document.querySelector("#ColumnLineChart"),
      options
    );
    chart.render();
    // console.log(recipesDetails);
    recipesDetails?.map((elm) => {
      const pId = elm;
      // console.log(pId);
      // ordersMap.set(pId, ordersMap.has(pId) ? ordersMap.get(pId) + 1 : 1);
    });

    const ordersTopTen = recipesDetails
      .sort((a, b) => b[1] - a[1])
      .slice(0, 26);

    // var top10ProductsNames = []
    var caloriesTotalValuePerRecipe = new Map([]);

    ordersTopTen.forEach((key, value) => {
      key.nutriFacts.foodNutrients.map((singleRecipeelm, index) => {
        // caloriesTotalValuePerRecipe.get(key);
        if (
          singleRecipeelm.nutrient.name === "Energy" &&
          singleRecipeelm.nutrient.unitName === "kcal"
        ) {
          var TotalEnergyInSingleRecipe = toFixed(
            (singleRecipeelm.amount / 100) *
              (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                parseFloat(key.qty))
          );
          //   console.log(singleRecipeelm.amount);

          if (caloriesTotalValuePerRecipe.has(key.recipe)) {
            caloriesTotalValuePerRecipe.set(key.recipe, [
              caloriesTotalValuePerRecipe.get(key.recipe)[0] +
                TotalEnergyInSingleRecipe,
              key.recipeName,
            ]);
          } else {
            caloriesTotalValuePerRecipe.set(key.recipe, [
              TotalEnergyInSingleRecipe,
              key.recipeName,
            ]);
          }
        }
      });
    });
    const returnedVals = [...caloriesTotalValuePerRecipe].map((v) => {
      var x = v.map((p, i) => {
        if (i % 2 !== 0) return p;
      });

      return toFixed(x[1][0]);
    });

    const returnedRecipesNames = [...caloriesTotalValuePerRecipe].map((v) => {
      var x = v.map((p, i) => {
        if (i % 2 !== 0) return p;
      });
      return x[1][1];
    });

    chart.updateSeries([
      {
        name: "",
        type: "column",
        data: returnedVals,
      },
      {
        name: "",
        type: "line",
        data: returnedVals,
      },
    ]);

    chart.updateOptions({
      labels: returnedRecipesNames,
    });
  }, [recipesDetails]);

  return (
    <>
      <Card
        sx={{
          display: "block",
          borderRadius: "20px 20px 20px 20px !important",
          padding: 2,
          margin: 0,
          "& .MuiCardContent-root": {
            padding: "10px !important",
          },
          boxShadow: 4,
          height: "calc(100vh - 370px)",
        }}
        className="shadow-xl drop-shadow-xl shadow-lime-700"
      >
        <CardContent
          sx={{
            display: "block",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Typography variant="h4" ml={3}>
            Recipes Stastistics
          </Typography>

          <br></br>
          <Box sx={{ height: "calc(100vh - 500px)" }} id="ColumnLineChart" />
        </CardContent>
      </Card>
    </>
  );
};

export default ColumnLineChart;
