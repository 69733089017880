import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";
import { toast } from "react-toastify";
import { toastSuccessOptions } from "../utils/constVals";
import assets from "../assets";
import { Fade, Modal, Box, TextField } from "@mui/material";

const modalStyle = {
  justifyContents: "center",
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: { xl: "25%", lg: "25%", sm: "100%", xs: "100%" },
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: { xl: "45%", lg: "45%", sm: "55%", xs: "55%" },
  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "none",
  borderRadius: "100px",
};

const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("username").trim();
    const password = data.get("password").trim();
    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await authApi.login({ username, password });
      setLoading(false);
      localStorage.setItem("token", res.token);
      toast.success("Logged in...", toastSuccessOptions);

      navigate("/app/dashboard");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      closeAfterTransition
      BackdropProps={{ timeout: 500 }}
      onClose={(e) => props.setIsOpen(false)}
    >
      <Fade in={props.isOpen}>
        <Box sx={modalStyle}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 4,
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/Calories (1).png"}
              alt="logo"
              height="10"
            />
            <Box
              component="form"
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "70%",
              }}
              onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                disabled={loading}
                error={usernameErrText !== ""}
                helperText={usernameErrText}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                disabled={loading}
                error={passwordErrText !== ""}
                helperText={passwordErrText}
              />
              <LoadingButton
                sx={{ mt: 3, mb: 2, width: "200px" }}
                variant="outlined"
                color="success"
                type="submit"
                loading={loading}
              >
                Login
              </LoadingButton>
              {/* <Button
              component={Link}
              to="/signup"
              sx={{ textTransform: "none" }}
            >
              Don't have an account? Signup
            </Button> */}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Login;
