import React, { useState } from "react";

const AddNewNutrition = () => {
  const [description, setDescription] = useState("");
  const [calories, setCalories] = useState(null);
  const [totalFat, setTotalFat] = useState(null);
  const [saturatedFat, setSaturatedFat] = useState(null);
  const [cholesterol, setCholesterol] = useState(null);
  const [sodium, setSodium] = useState(null);
  const [totalCarbohydrate, setTotalCarbohydrate] = useState(null);
  const [dietaryFiber, setDietaryFiber] = useState(null);
  const [totalSugars, setTotalSugars] = useState(null);
  const [addedSugars, setAddedSugars] = useState(null);
  const [protein, setProtein] = useState(null);
  const [vitaminA, setVitaminA] = useState(null);
  const [vitaminC, setVitaminC] = useState(null);
  const [calcium, setCalcium] = useState(null);
  const [iron, setIron] = useState(null);
  const [potassium, setPotassium] = useState(null);

  const extractData = () => {
    const nutrientData = {
      description,
      foodNutrients: [
        {
          nutrient: {
            id: 1008,
            name: "Calories",
            unitName: "kcal",
          },
          amount: calories,
        },
        {
          nutrient: {
            id: 1253,
            name: "Total fat",
            unitName: "g",
          },
          amount: totalFat,
        },
        {
          nutrient: {
            id: 1257,
            name: "Saturated fat",
            unitName: "g",
          },
          amount: saturatedFat,
        },
        {
          nutrient: {
            id: 1258,
            name: "Cholesterol",
            unitName: "mg",
          },
          amount: cholesterol,
        },
        {
          nutrient: {
            id: 1259,
            name: "Sodium",
            unitName: "mg",
          },
          amount: sodium,
        },
        {
          nutrient: {
            id: 1251,
            name: "Total carbohydrate",
            unitName: "g",
          },
          amount: totalCarbohydrate,
        },
        {
          nutrient: {
            id: 1252,
            name: "Dietary fiber",
            unitName: "g",
          },
          amount: dietaryFiber,
        },
        {
          nutrient: {
            id: 1250,
            name: "Total sugars",
            unitName: "g",
          },
          amount: totalSugars,
        },
        {
          nutrient: {
            id: 1266,
            name: "Added sugars",
            unitName: "g",
          },
          amount: addedSugars,
        },
        {
          nutrient: {
            id: 1003,
            name: "Protein",
            unitName: "g",
          },
          amount: protein,
        },
        {
          nutrient: {
            id: 1004,
            name: "Vitamin A",
            unitName: "IU",
          },
          amount: vitaminA,
        },
        {
          nutrient: {
            id: 1005,
            name: "Vitamin C",
            unitName: "mg",
          },
          amount: vitaminC,
        },
        {
          nutrient: {
            id: 1006,
            name: "Calcium",
            unitName: "mg",
          },
          amount: calcium,
        },
        {
          nutrient: {
            id: 1007,
            name: "Iron",
            unitName: "mg",
          },
          amount: iron,
        },
        {
          nutrient: {
            id: 1010,
            name: "Potassium",
            unitName: "mg",
          },
          amount: potassium,
        },
      ],
    };
    console.log(nutrientData);
  };

  return (
    <div className="flex flex-col w-6/12 ">
      <label htmlFor="description">Description:</label>
      <input
        type="text"
        id="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <label htmlFor="calories">Calories:</label>
      <input
        type="number"
        id="calories"
        value={calories}
        onChange={(e) => setCalories(e.target.value)}
      />
      <label htmlFor="totalFat">Total Fat:</label>
      <input
        type="number"
        id="totalFat"
        value={totalFat}
        onChange={(e) => setTotalFat(e.target.value)}
      />
      <label htmlFor="saturatedFat">Saturated Fat:</label>
      <input
        type="number"
        id="saturatedFat"
        value={saturatedFat}
        onChange={(e) => setSaturatedFat(e.target.value)}
      />
      <label htmlFor="cholesterol">Cholesterol:</label>
      <input
        type="number"
        id="cholesterol"
        value={cholesterol}
        onChange={(e) => setCholesterol(e.target.value)}
      />
      <label htmlFor="sodium">Sodium:</label>
      <input
        type="number"
        id="sodium"
        value={sodium}
        onChange={(e) => setSodium(e.target.value)}
      />
      <label htmlFor="totalCarbohydrate">Total Carbohydrate:</label>
      <input
        type="number"
        id="totalCarbohydrate"
        value={totalCarbohydrate}
        onChange={(e) => setTotalCarbohydrate(e.target.value)}
      />
      <label htmlFor="dietaryFiber">Dietary Fiber:</label>
      <input
        type="number"
        id="dietaryFiber"
        value={dietaryFiber}
        onChange={(e) => setDietaryFiber(e.target.value)}
      />
      <label htmlFor="totalSugars">Total Sugars:</label>
      <input
        type="number"
        id="totalSugars"
        value={totalSugars}
        onChange={(e) => setTotalSugars(e.target.value)}
      />
      <label htmlFor="addedSugars">Added Sugars:</label>
      <input
        type="number"
        id="addedSugars"
        value={addedSugars}
        onChange={(e) => setAddedSugars(e.target.value)}
      />
      <label htmlFor="protein">Protein:</label>
      <input
        type="number"
        id="protein"
        value={protein}
        onChange={(e) => setProtein(e.target.value)}
      />
      <label htmlFor="vitaminA">Vitamin A:</label>
      <input
        type="number"
        id="vitaminA"
        value={vitaminA}
        onChange={(e) => setVitaminA(e.target.value)}
      />
      <label htmlFor="vitaminC">Vitamin C:</label>
      <input
        type="number"
        id="vitaminC"
        value={vitaminC}
        onChange={(e) => setVitaminC(e.target.value)}
      />
      <label htmlFor="calcium">Calcium:</label>
      <input
        type="number"
        id="calcium"
        value={calcium}
        onChange={(e) => setCalcium(e.target.value)}
      />

      <label htmlFor="iron">Iron:</label>
      <input
        type="number"
        id="iron"
        value={iron}
        onChange={(e) => setIron(e.target.value)}
      />
      <label htmlFor="potassium">Potassium:</label>
      <input
        type="number"
        id="potassium"
        value={potassium}
        onChange={(e) => setPotassium(e.target.value)}
      />
      <button onClick={extractData}>Extract Data</button>
    </div>
  );
};

export default AddNewNutrition;
