import {
  Box,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// import assets from '../../assets'
import boardApi from "../../api/boardApi";
import { setBoards } from "../../redux/features/boardSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FavouriteList from "./FavouriteList";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { toast } from "react-toastify";
import { toastErrorOptions } from "../../utils/constVals";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

const Sidebar = () => {
  const user = useSelector((state) => state.user.value);
  const boards = useSelector((state) => state.board.value);
  const sidebarWidth = 250;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const getBoards = async () => {
      try {
        const res = await boardApi.getAll();
        dispatch(setBoards(res));
      } catch (err) {
        toast.success(err, toastErrorOptions);
      }
    };
    getBoards();
  }, [dispatch]);

  useEffect(() => {
    const activeItem = boards.findIndex((e) => e.id === boardId);
    //  if (boards.length > 0 && boardId === undefined) {
    //   navigate(`/boards/${boards[0].id}`)
    // }
    setActiveIndex(activeItem);
  }, [boards, boardId, navigate]);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
    toast.error("Logged Out", toastErrorOptions);
  };

  const onDragEnd = async ({ source, destination }) => {
    const newList = [...boards];
    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);
    const activeItem = newList.findIndex((e) => e.id === boardId);
    setActiveIndex(activeItem);
    dispatch(setBoards(newList));
    try {
      await boardApi.updatePosition({ boards: newList });
      toast.success("Category Position Updated", toastErrorOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const addBoard = async () => {
    try {
      const res = await boardApi.create();
      const newList = [res, ...boards];
      dispatch(setBoards(newList));
      navigate(`/app/boards/${res.id}`);
      toast.success("Main Category Created", toastErrorOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  return (
    <>
      <Drawer
        container={window.document.body}
        variant="permanent"
        open={true}
        sx={{
          width: sidebarWidth,
          paddingRight: 38,
          height: "calc(100vh - 70px)",
          "& > div": { borderRight: "none" },
          "& .MuiPaper-root": {
            position: "absolute",
            overflow: "hidden",
          },

          boxShadow: 3,
        }}
        PaperProps={{
          style: {
            position: "absolute",
            marginTop: "70px",
            height: "calc(100vh - 70px)",
            paddingLeft: 4,
          },
        }}
      >
        <List
          disablePadding
          sx={{
            width: sidebarWidth - 200,
            height: "calc(100vh - 70px)",
            position: "absolute",
            overflow: "hidden",
          }}
        >
          <ListItem>
            <Tooltip title="Home" placement="right" arrow>
              <IconButton
                sx={{
                  marginLeft: "-18px",
                }}
                onClick={() => {
                  navigate(`/app/dashboard`);
                }}
              >
                <AnalyticsOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem>
            <Tooltip title="Categories" placement="right" arrow>
              <IconButton
                sx={{
                  marginLeft: "-18px",
                }}
                onClick={() => {
                  navigate(`/app/boards/${boards[0].id}`);
                }}
              >
                <CategoryIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem>
            <Tooltip title="Ingredients Costs" placement="right" arrow>
              <IconButton
                sx={{
                  marginLeft: "-18px",
                }}
                onClick={() => {
                  navigate(`/app/costs`);
                }}
              >
                <AttachMoneyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem>
            <Tooltip title="Profile" placement="right" arrow>
              <IconButton
                sx={{
                  marginLeft: "-18px",
                }}
                onClick={() => {
                  navigate("/app/profile");
                }}
              >
                <AccountBoxIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem>
            <Tooltip title="Tutorials" placement="right" arrow>
              <IconButton
                sx={{
                  marginLeft: "-18px",
                }}
              >
                <AutoStoriesIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem sx={{ position: "absolute", bottom: "0" }}>
            <Tooltip title="Logout" placement="right" arrow>
              <IconButton
                onClick={logout}
                sx={{
                  marginLeft: "-18px",
                }}
              >
                <LogoutOutlinedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </ListItem>
        </List>
        <List
          disablePadding
          sx={{
            width: sidebarWidth,
            height: "calc(100vh - 70px)",
            marginLeft: "50px",
            overflow: "hidden",
            boxShadow: 2,
          }}
        >
          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <Typography variant="body2" fontWeight="700">
                Welcome {user.name}!
              </Typography>
            </Box>
          </ListItem>
          <Box sx={{ paddingTop: "10px" }} />
          <FavouriteList />
          <Box sx={{ paddingTop: "10px" }} />

          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",

                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" fontWeight="700">
                Categories
              </Typography>
              <IconButton onClick={addBoard}>
                <AddBoxOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </ListItem>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              key={"list-board-droppable"}
              droppableId={"list-board-droppable"}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {boards.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <ListItemButton
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          selected={index === activeIndex}
                          component={Link}
                          to={`./boards/${item.id}`}
                          sx={{
                            pl: "20px",
                            curson: snapshot.isDragging ? "grab" : "pointer",
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontWeight="700"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.icon} {item.title}
                          </Typography>
                        </ListItemButton>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
