import React from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { baseUrl } from "../api/axiosClient";
import Login from "./Login";
const Home = (props) => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  let servicesBlock = useRef();
  let howitworksBlock = useRef();
  let contactusBlock = useRef();
  const [status, setStatus] = useState("Submit");
  const [IsOpen, setIsOpen] = useState(false);
  const handleSignin = () => {
    // navigate("/app");
    setIsOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    const { name, email, message, company, country } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
      company: company.value,
      country: country.value,
    };

    let response = await fetch(baseUrl + "contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
  };

  return (
    <>
      <div className="relative w-full h-full pb-10">
        <div className="hidden md:block">
          <img
            className="absolute bg-cover bg-center w-full h-full inset-0"
            src={process.env.PUBLIC_URL + "/assets/images/hero-banner.jpg"}
          />
        </div>
        <nav className="lg:hidden relative z-50">
          <div className="flex py-2 justify-between items-center px-4">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Calories (1).png"}
                alt="logo"
              />
            </div>
            <div className="visible flex items-center">
              <button
                id="open"
                onClick={() => setMenu(!menu)}
                className={` ${
                  menu ? "hidden" : ""
                } focus:outline-none focus:ring-2 focus:ring-black`}
              >
                <img
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/large_typography_with_gradient_and_glass_effect_Svg2.svg"
                  alt="menu"
                />
              </button>
              <ul
                id="list"
                className={` ${
                  menu ? "" : "hidden"
                } p-2 border-r bg-white absolute rounded top-0 left-0 right-0 shadow mt-24`}
              >
                <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                  <a
                    href=""
                    className="ml-2 focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    <span className="font-bold">Home</span>
                  </a>
                </li>
                <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                  <a
                    className="ml-2 focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: servicesBlock.current.offsetTop,
                      });
                    }}
                  >
                    <span className="font-bold">Services</span>
                  </a>
                </li>
                <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                  <a
                    className="ml-2 focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: howitworksBlock.current.offsetTop,
                      });
                    }}
                  >
                    <span className="font-bold">How it Works</span>
                  </a>
                </li>
                <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: contactusBlock.current.offsetTop,
                      });
                    }}
                    className="ml-2 focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    <span className="font-bold">Contact us</span>
                  </a>
                </li>
                <li className="flex cursor-pointer text-gray-600 text-sm leading-3 tracking-normal pt-2 pb-4 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                  <Button
                    className="ml-2 focus:out line-none focus:ring-2 focus:ring-black"
                    onClick={handleSignin}
                  >
                    <span className="font-bold">Sign In</span>
                  </Button>
                </li>
              </ul>
              <div className="xl:hidden">
                <button
                  id="close"
                  className={` ${
                    menu ? "" : "hidden"
                  } close-m-menu focus:ring-2 focus:ring-black focus:outline-none`}
                  onClick={() => setMenu(!menu)}
                >
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/large_typography_with_gradient_and_glass_effect_Svg3.svg"
                    alt="close"
                  />
                </button>
              </div>
            </div>
          </div>
        </nav>
        <nav className="f-f-l relative z-10">
          <div className="relative z-10 mx-auto container hidden w-full px-4 xl:px-0 lg:flex justify-between items-center py-11">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/Calories (1).png"}
                alt="logo"
              />
            </div>
            <div className="flex items-center text-white text-base font-medium">
              <ul className="flex items-center pr-3 xl:pr-12">
                <li className="cursor-pointer hover:text-gray-300 ease-in">
                  <a
                    href=""
                    className="focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    Home
                  </a>
                </li>
                <li className="pl-3 lg:pl-5 xl:pl-8 cursor-pointer hover:text-gray-300 ease-in">
                  <a
                    className="ml-2 focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: servicesBlock.current.offsetTop,
                      });
                    }}
                  >
                    <span className="font-bold">Services</span>
                  </a>
                </li>
                <li className="pl-3 lg:pl-5 xl:pl-8 cursor-pointer hover:text-gray-300 ease-in">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: howitworksBlock.current.offsetTop,
                      });
                    }}
                    className="focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    How it Works
                  </a>
                </li>
                <li className="pl-3 lg:pl-5 xl:pl-8 cursor-pointer hover:text-gray-300 ease-in">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: contactusBlock.current.offsetTop,
                      });
                    }}
                    className="focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
              <button
                onClick={handleSignin}
                className="px-6 py-3 bg-lime-700 hover:bg-lime-900 text-white text-base font-medium rounded-lg flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Sign In
                <img
                  className="ml-4"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/large_typography_with_gradient_and_glass_effect_Svg5.svg"
                  alt="arrow"
                />
              </button>
            </div>
          </div>
        </nav>
        <div className="relative px-4 xl:px-0 container mx-auto md:flex items-center gap-8">
          <div className="text-color w-full md:w-1/3 pt-16 lg:pt-32 xl:pt-12">
            <h1 className="text-4xl md:text-4xl lg:text-6xl w-11/12 lg:w-11/12 xl:w-full xl:text-6xl text-gray-900 font-extrabold f-f-l">
              Clarity in every bite
            </h1>
            <div className="f-f-r text-base lg:text-base pb-20 sm:pb-0 pt-10 xl:pt-6">
              <h2>
                Introducing a comprehensive, innovative, and user-friendly
                solution for all your food labeling needs. Our reliable and
                customizable service makes it easy to comply with regulations
                and provide accurate and transparent information to consumers.
              </h2>
            </div>
            <div className="lg:flex">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({
                    behavior: "smooth",
                    top: contactusBlock.current.offsetTop,
                  });
                }}
                className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-lime-500 hover:bg-lime-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg"
              >
                Start Now
              </button>
              {/* <button className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 lg:ml-2 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-200 text-indigo-600 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">
                Try it out
              </button> */}
            </div>
          </div>
          <img
            className="w-full mt-8 md:mt-0 object-fill md:w-2/3 md:-ml-4 lg:-ml-4 xl:ml-0 rounded-3xl shadow-gray-900 shadow-2xl"
            src={process.env.PUBLIC_URL + "/assets/images/hero.jpg"}
            alt="sample page"
            role="img"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              window.scrollTo({
                behavior: "smooth",
                top: contactusBlock.current.offsetTop,
              });
            }}
            className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-lime-500 hover:bg-lime-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg"
          >
            Start Now
          </button>
        </div>
      </div>

      <style>{`
        .top-100 {
            animation: slideDown .5s ease-in-out;
        }

        @keyframes slideDown {
            0% {
                top: -50%;
            }

            100% {
                top: 0;
            }
        }

        * {
            outline: none !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        } `}</style>
      {/* ##### numbers */}
      <div>
        <div className="pb-20">
          <div className="mx-auto bg-lime-500 h-96">
            <div className="mx-auto container w-full flex flex-col justify-center items-center">
              <div className="flex justify-center items-center flex-col">
                <div className="mt-20">
                  <h2 className="lg:text-6xl md:text-5xl text-4xl font-black leading-10 text-white">
                    By The Numbers
                  </h2>
                </div>
                <div className="mt-6 mx-2 md:mx-0 text-center">
                  <p className="lg:text-lg md:text-base leading-6 text-sm  text-white">
                    Nutrition Made Simple
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto container md:-mt-28 -mt-20 flex justify-center items-center">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-x-2 gap-y-2 lg:gap-x-6 md:gap-x-6 md:gap-y-6 md:gap-y-6">
              <div className="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl shadow-xl shadow-lime-400">
                <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                  40+
                </h2>
                <p className="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600">
                  Happy Clients
                </p>
              </div>
              <div className="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl shadow-xl shadow-lime-400">
                <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                  500K+
                </h2>
                <p className="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600">
                  Ingredients
                </p>
              </div>
              <div className="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl shadow-xl shadow-lime-400">
                <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                  10K+
                </h2>
                <p className="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600">
                  API Requests/hour
                </p>
              </div>
              <div className="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl shadow-xl shadow-lime-400">
                <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                  100+
                </h2>
                <p className="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600">
                  Recipes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ####services  */}
      <div className="overflow-y-hidden" id="services" ref={servicesBlock}>
        <div className="pb-16" style={{ fontFamily: '"Lato", sans-serif' }}>
          {/* Code block starts */}
          <dh-component>
            <section className="mx-auto container bg-white pt-16">
              <div className="px-4 lg:px-0">
                <div
                  role="contentinfo"
                  className="flex items-center flex-col px-4"
                >
                  <p
                    tabIndex={0}
                    className="focus:outline-none uppercase text-sm text-center text-gray-500 leading-none"
                  >
                    Services
                  </p>
                  <h1
                    tabIndex={0}
                    className="focus:outline-none text-4xl lg:text-4xl pt-4 font-extrabold text-center leading-tight text-gray-800 lg:w-7/12 md:w-9/12 xl:w-5/12"
                  >
                    Transforming Your Business With Our Expert Services
                  </h1>
                </div>
              </div>
            </section>
            <section className="bg-white py-12 mt-12">
              <div
                tabIndex={0}
                aria-label="group of cards"
                className="focus:outline-none px-4 lg:px-0"
              >
                <div className="mx-auto container flex flex-wrap px-2 lg:px-24">
                  <div
                    tabIndex={0}
                    aria-label="card 1"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-r md:border-r sm:border-r-0 border-lime-300 pb-10 lg:pt-10"
                  >
                    <div className=" flex flex-shrink-0 mr-5 text-black">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg1.svg"
                        alt="drawer"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-black"
                      >
                        Your Subscription
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-black leading-normal xl:w-10/12 pt-2"
                      >
                        <ul className="list-outside list-disc ml-6 text-black font-medium">
                          <li>Unlimited Recipe Creation</li>
                          <li>Recipe Costing Tool</li>
                          <li>Nutrition Facts Labels</li>
                          <li>Arabic & English Labels</li>
                          <li>USDA Approved Data </li>
                          <li>Support 24/7</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 2"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:pb-10 lg:pt-10"
                  >
                    <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-black">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg"
                        alt="check"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-black"
                      >
                        Added Value Services
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-black leading-normal xl:w-10/12 pt-2"
                      >
                        <ul className="list-outside list-disc ml-6 text-black font-medium">
                          <li>Menu Consultation</li>
                          <li>Operation Audit</li>
                          <li>Up to 15 Recipes Free Data Entry</li>
                          <li>Culinary & Nutrition Training for your team</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 3"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 lg:border-r md:border-r sm:border-r-0 border-lime-300 pt-10 lg:pb-20"
                  >
                    <div className=" flex flex-shrink-0 mr-5 text-black">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg3.svg"
                        alt="html-tag"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-black"
                      >
                        Additional Services
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-black leading-normal xl:w-10/12 pt-2"
                      >
                        <ul className="list-outside list-disc ml-6 text-black font-medium">
                          <li> Menu Development</li>
                          <li>Menu Costing</li>
                          <li>Operation Training</li>
                          <li>Front of House Training</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 4"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 border-lime-300 pt-10 lg:pb-20"
                  >
                    <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-black">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg4.svg"
                        alt="display"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-black"
                      >
                        Technical Services
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-black leading-normal xl:w-10/12 pt-2"
                      >
                        <ul className="list-outside list-disc ml-6 text-black font-medium">
                          <li>
                            Free migration to make switching easy and
                            stress-free
                          </li>
                          <li>Daily Backups</li>
                          <li>API Subscription</li>
                          <li>Your data is 100% secure</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </dh-component>
          {/* Code block ends */}
        </div>
      </div>
      {/* #### how it works */}
      <div classname="overflow-y-hidden bg-gray-100" ref={howitworksBlock}>
        <div className="mx-auto container f-f-p px-4 xl:px-0 ">
          <h1 className="focus:outline-none text-center text-3xl lg:text-4xl font-extrabold lg:leading-9 tracking-wider text-gray-900">
            How it works?
          </h1>
          <div className="md:mt-24 f-f-p">
            <div className="hidden md:flex justify-center w-full">
              <div className="flex flex-col items-center md:items-end md:pr-12 md:border-r border-lime-300 relative md:w-1/2">
                <div aria-label="sign up" role="img">
                  <img
                    className="focus:outline-none mt-10"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/howsignup.svg"
                    }
                    alt="how it work"
                  />
                </div>
                <div aria-label={2} role="img">
                  <img
                    className="focus:outline-none mt-24"
                    src={process.env.PUBLIC_URL + "/assets/images/howto2.svg"}
                  />
                </div>
                <div className="flex mt-12 flex-col items-center lg:items-end md:w-8/12">
                  <h1 className="focus:outline-none text-xl font-bold leading-5">
                    Create a Recipe
                  </h1>
                  <h2 className="focus:outline-none text-gray-500 mt-3 pl-3 text-center md:text-right text-base leading-6 tracking-wide">
                    Create recipes and start add ingredients and access the
                    Nutrition facts labels and stastics.
                  </h2>
                </div>
                <div aria-label="transactions" role="img">
                  <img
                    className="focus:outline-none mt-24"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/howtoprint.svg"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start md:pl-12 lg:border-gray-400 mt-20 md:mt-0 md:w-1/2">
                <div aria-label={1} role="img">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/howto1.svg"}
                  />
                </div>
                <div className="flex mt-6 flex-col items-center md:items-start md:w-8/12">
                  <h1 className="focus:outline-none text-xl font-bold leading-5">
                    SignUp for an Account
                  </h1>
                  <h2 className="focus:outline-none text-gray-500 mt-3 text-base leading-6 tracking-wide">
                    Sign up for an account with us to access exclusive features
                    and benefits!
                  </h2>
                </div>
                <div aria-label="wallet" role="img">
                  <img
                    className="focus:outline-none mt-32"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/howtoaddrecipe.svg"
                    }
                  />
                </div>
                <div aria-label={3} role="img">
                  <img
                    className="focus:outline-none mt-20"
                    src={process.env.PUBLIC_URL + "/assets/images/howto3.svg"}
                  />
                </div>
                <div className="flex mt-6 flex-col items-center md:items-start md:w-8/12">
                  <h1 className="focus:outline-none text-xl font-bold leading-5">
                    Print Your Labels
                  </h1>
                  <h2 className="focus:outline-none text-gray-500 mt-3 text-base leading-6 tracking-wide">
                    Start Printing right away. No more complicated process for
                    anything else.
                  </h2>
                </div>
              </div>
            </div>
            <div className="md:hidden flex flex-col items-center w-full">
              <img
                className="focus:outline-none my-10"
                src={process.env.PUBLIC_URL + "/assets/images/howsignup.svg"}
                alt="how it work"
              />
              <img src={process.env.PUBLIC_URL + "/assets/images/howto1.svg"} />
              <div className="mt-10">
                <h1 className="text-xl text-center tracking-wide leading-5 font-bold">
                  SignUp for an Account
                </h1>
                <h2 className="text-gray-500 mt-3 text-center text-base leading-6 tracking-wide">
                  Sign up for an account with us to access exclusive features
                  and benefits!
                </h2>
              </div>
              <img
                className="focus:outline-none my-10"
                src={
                  process.env.PUBLIC_URL + "/assets/images/howtoaddrecipe.svg"
                }
              />
              <img src={process.env.PUBLIC_URL + "/assets/images/howto2.svg"} />
              <div className="mt-10">
                <h1 className="text-xl tracking-wide text-center leading-5 font-bold">
                  Create a Recipe
                </h1>
                <h2 className="text-gray-500 mt-3 pl-3 text-center text-base leading-6 tracking-wide">
                  Create a recipe by and start add ingredients and access the
                  nutrition facts labels and stastics.
                </h2>
              </div>
              <img
                className="focus:outline-none my-10"
                src={process.env.PUBLIC_URL + "/assets/images/howtoprint.svg"}
              />
              <img
                className
                src={process.env.PUBLIC_URL + "/assets/images/howto3.svg"}
              />
              <div className="flex mt-10 flex-col items-center md:items-start md:w-8/12">
                <h1 className="text-xl text-center tracking-wide leading-5 font-bold">
                  Print Your Labels
                </h1>
                <h2 className="text-gray-500 mt-3 text-center text-base leading-6 tracking-wide">
                  Start Printing right away. No more complicated process for
                  anything else.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ### contact form */}
      <form onSubmit={handleSubmit}>
        <div
          className="w-full flex items-center justify-center mt-24  "
          ref={contactusBlock}
        >
          <div className=" bg-white shadow rounded py-12 lg:px-28 px-8 shadow-inner shadow-lime-300 drop-shadow-xl">
            <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">
              Let’s chat and get a quote!
            </p>
            <div className="md:flex items-center mt-12">
              <div className="md:w-72 flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Name
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input name"
                  type="name"
                  id="name"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-lime-300 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input  name"
                />
              </div>
              <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Email Address
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input email address"
                  type="email"
                  id="email"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-lime-300 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input email address"
                />
              </div>
            </div>
            <div className="md:flex items-center mt-8">
              <div className="md:w-72 flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Business name
                </label>
                <input
                  tabIndex={0}
                  role="input"
                  arial-label="Please input company name"
                  type="name"
                  id="company"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-lime-300 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 "
                  placeholder="Please input company name"
                />
              </div>
              <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Country
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input country name"
                  type="name"
                  id="country"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-lime-300 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input country name"
                />
              </div>
            </div>
            <div>
              <div className="w-full flex flex-col mt-8">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Message
                </label>
                <textarea
                  tabIndex={0}
                  aria-label="leave a message"
                  role="textbox"
                  id="message"
                  type="name"
                  className="h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-lime-300 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 resize-none"
                  defaultValue={""}
                />
              </div>
            </div>
            <p className="text-xs leading-3 text-gray-600 mt-4">
              By clicking submit you agree to our terms of service, privacy
              policy and how we use data as stated
            </p>
            <div className="flex items-center justify-center w-full">
              <button
                type="submit"
                className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-lime-500 rounded hover:bg-lime-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none"
              >
                {status}
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* ####footer */}

      <div className>
        <div className="relative flex justify-start md:justify-center md:items-end ">
          <img
            className="absolute object-cover  top-10 h-full w-full xl:mt-10 z-0"
            src={process.env.PUBLIC_URL + "/assets/images/foote_background.png"}
            alt="background"
          />
          <div className="flex pt-36 md:pt-32 lg:pt-40 xl:pt-96   px-4 md:px-6  xl:px-20 flex-col justify-start items-start md:justify-center md:items-center relative z-10">
            <div className="flex  flex-col items-start justify-start xl:justify-center xl:space-x-8 xl:flex-row">
              <div className="flex justify-start items-center space-x-4">
                <div className="cursor-pointer  bg-neutral-200 rounded-3xl">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Calories (1).png"
                    }
                    alt="logo"
                  />
                </div>
                <p className="w-60 text-xl xl:text-2xl font-semibold leading-normal text-white"></p>
              </div>
              <div className="mt-12 xl:mt-0 grid grid-cols-1 sm:grid-cols-3 gap-y-12 sm:gap-y-0 w-full md:w-auto sm:gap-x-20 md:gap-x-28 xl:gap-8">
                <div className="sm:w-40 md:w-auto xl:w-72 flex justify-start items-start flex-col space-y-6">
                  <h2 className="text-base xl:text-xl font-bold xl:font-semibold leading-4 xl:leading-5 text-white">
                    Information
                  </h2>
                  <div>
                    <ul className=" text-white">
                      <li className="flex space-x-2 pb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 "
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
                          />
                        </svg>
                        <p>support@caloriespal.com</p>
                      </li>
                      <li className="flex space-x-2 pb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                          />
                        </svg>

                        <p>+917 58 520 1813</p>
                      </li>
                      <li className="flex space-x-2 pb-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                          />
                        </svg>

                        <p>Dubai, UAE.</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sm:w-40 md:w-auto xl:w-72 flex justify-start items-start flex-col space-y-6">
                  <h2 className="text-base xl:text-xl font-bold xl:font-semibold leading-4 xl:leading-5 text-white">
                    Getting Started
                  </h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: contactusBlock.current.offsetTop,
                      });
                    }}
                    className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                  >
                    Signup!
                  </button>
                  <button
                    onClick={handleSignin}
                    className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                  >
                    Signin
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: servicesBlock.current.offsetTop,
                      });
                    }}
                    className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                  >
                    Services
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        behavior: "smooth",
                        top: howitworksBlock.current.offsetTop,
                      });
                    }}
                    className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                  >
                    How it Works
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-12 flex  xl:justify-between xl:flex-row flex-col-reverse items-center xl:items-start w-full ">
              <p className="mt-10 md:mt-12 xl:mt-0 text-sm leading-none text-white">
                2023 Calories Pal Company. All Rights Reserved
              </p>
              <div className="mt-10 md:mt-12 xl:mt-0 md:flex-row flex-col flex md:justify-center w-full md:w-auto justify-start items-start space-y-4 md:space-y-0 md:items-center md:space-x-4 xl:space-x-6"></div>
              <div className="flex  justify-start md:justify-end items-start  w-full md:w-auto md:items-center space-x-6 ">
                <button className="text-white hover:text-gray-200 w-6">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.25 5.13282C22.406 5.49955 21.513 5.74116 20.5992 5.85001C21.5595 5.28769 22.2817 4.39434 22.6303 3.33751C21.7224 3.86841 20.7307 4.24092 19.6978 4.43907C19.2629 3.98322 18.7397 3.62059 18.1603 3.3732C17.5808 3.12581 16.9571 2.99884 16.327 3.00001C13.7761 3.00001 11.7117 5.03438 11.7117 7.5422C11.7099 7.89102 11.7499 8.23881 11.8308 8.57813C10.0016 8.49238 8.2104 8.02575 6.57187 7.2081C4.93333 6.39044 3.48351 5.23977 2.31516 3.8297C1.90527 4.52069 1.6885 5.30909 1.6875 6.11251C1.6875 7.68751 2.50922 9.0797 3.75 9.89532C3.01487 9.87787 2.29481 9.68331 1.65094 9.32813V9.38438C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9433 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3333 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07188C20.9616 7.87501 20.9564 7.67813 20.947 7.48595C21.8485 6.84472 22.6283 6.04787 23.25 5.13282V5.13282Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <button className="text-white hover:text-gray-200 w-6">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.5 12.0645C22.5 6.26602 17.7984 1.56445 12 1.56445C6.20156 1.56445 1.5 6.26602 1.5 12.0645C1.5 17.3051 5.33906 21.649 10.3594 22.4374V15.1005H7.69266V12.0645H10.3594V9.75117C10.3594 7.12008 11.9273 5.66555 14.3255 5.66555C15.4744 5.66555 16.6763 5.87086 16.6763 5.87086V8.45508H15.3516C14.048 8.45508 13.6402 9.26414 13.6402 10.0957V12.0645H16.552L16.087 15.1005H13.6406V22.4384C18.6609 21.6504 22.5 17.3065 22.5 12.0645Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <button className="text-white hover:text-gray-200 w-6">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.5C6.20297 1.5 1.5 6.20297 1.5 12C1.5 17.797 6.20297 22.5 12 22.5C17.797 22.5 22.5 17.8022 22.5 12C22.5 6.19781 17.797 1.5 12 1.5ZM18.6666 6.33984C19.8815 7.76805 20.6046 9.54925 20.7291 11.4202C18.8367 11.3217 16.5727 11.3217 14.7572 11.4914C14.5275 10.9116 14.2922 10.343 14.0353 9.79594C16.1288 8.88844 17.7422 7.69594 18.6666 6.33984ZM12 3.24984C14.0395 3.24634 16.0156 3.95866 17.5837 5.26266C16.6322 6.46547 15.1392 7.51266 13.2797 8.30297C12.2625 6.42188 11.092 4.80328 9.84375 3.52875C10.5482 3.3469 11.2725 3.25322 12 3.24984V3.24984ZM8.04047 4.20703C9.30375 5.46469 10.4906 7.06641 11.5298 8.94141C9.55547 9.54469 7.29141 9.89062 4.875 9.89062C4.41562 9.89062 3.9675 9.87422 3.52453 9.84656C3.83266 8.63935 4.3949 7.5118 5.17367 6.53927C5.95244 5.56673 6.92981 4.77161 8.04047 4.20703V4.20703ZM3.26625 11.5842C3.76406 11.6063 4.26703 11.617 4.77562 11.6119C7.53187 11.5791 10.0969 11.1469 12.3009 10.44C12.5088 10.8722 12.7056 11.3152 12.8916 11.7689C12.6252 11.8272 12.3637 11.9056 12.1092 12.0033C9.09609 13.2689 6.72281 15.3084 5.4375 17.775C4.02813 16.1814 3.25007 14.1274 3.24984 12C3.24984 11.8594 3.25547 11.7211 3.26625 11.5842ZM12 20.7502C10.056 20.7525 8.16724 20.1036 6.63516 18.907C7.8 16.5994 9.87797 14.6883 12.4978 13.5037C12.7439 13.3889 13.072 13.2961 13.4494 13.2141C13.7619 14.0778 14.0408 14.9747 14.2861 15.9047C14.6525 17.3083 14.9223 18.7354 15.0938 20.1759C14.1061 20.5543 13.0576 20.7489 12 20.7502V20.7502ZM16.7306 19.3556C16.5595 17.9001 16.2871 16.4584 15.9155 15.0408C15.728 14.3297 15.5166 13.6406 15.2869 12.968C17.0039 12.8273 19.0603 12.8475 20.6953 12.968C20.5512 14.2606 20.1206 15.5047 19.4347 16.6097C18.7488 17.7148 17.825 18.6529 16.7306 19.3556V19.3556Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <button className="text-white hover:text-gray-200 w-6">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.5C6.20156 1.5 1.5 6.32344 1.5 12.2672C1.5 17.025 4.50937 21.0562 8.68125 22.4812C8.73977 22.494 8.79949 22.5002 8.85938 22.5C9.24844 22.5 9.39844 22.2141 9.39844 21.9656C9.39844 21.7078 9.38906 21.0328 9.38437 20.1328C9.03705 20.2142 8.68173 20.2567 8.325 20.2594C6.30469 20.2594 5.84531 18.6891 5.84531 18.6891C5.36719 17.4469 4.67813 17.1141 4.67813 17.1141C3.76406 16.4719 4.67344 16.4531 4.74375 16.4531H4.74844C5.80313 16.5469 6.35625 17.5687 6.35625 17.5687C6.88125 18.4875 7.58437 18.7453 8.2125 18.7453C8.62783 18.737 9.03673 18.6412 9.4125 18.4641C9.50625 17.7703 9.77812 17.2969 10.0781 17.025C7.74844 16.7531 5.29688 15.8297 5.29688 11.7047C5.29688 10.5281 5.70469 9.56719 6.375 8.81719C6.26719 8.54531 5.90625 7.44844 6.47812 5.96719C6.55483 5.94883 6.63368 5.94095 6.7125 5.94375C7.09219 5.94375 7.95 6.08906 9.36563 7.07344C11.0857 6.59218 12.9049 6.59218 14.625 7.07344C16.0406 6.08906 16.8984 5.94375 17.2781 5.94375C17.357 5.94095 17.4358 5.94883 17.5125 5.96719C18.0844 7.44844 17.7234 8.54531 17.6156 8.81719C18.2859 9.57187 18.6937 10.5328 18.6937 11.7047C18.6937 15.8391 16.2375 16.7484 13.8984 17.0156C14.2734 17.3484 14.6109 18.0047 14.6109 19.0078C14.6109 20.4469 14.5969 21.6094 14.5969 21.9609C14.5969 22.2141 14.7422 22.5 15.1312 22.5C15.1942 22.5003 15.2571 22.494 15.3187 22.4812C19.4953 21.0562 22.5 17.0203 22.5 12.2672C22.5 6.32344 17.7984 1.5 12 1.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login isOpen={IsOpen} setIsOpen={(e) => setIsOpen(e)}></Login>
    </>
  );
};

export default Home;
