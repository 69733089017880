import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: [] };

export const currentBoardSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentBoard: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentBoard } = currentBoardSlice.actions;

export default currentBoardSlice.reducer;
