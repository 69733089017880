export const lngs = {
  en: { nativeName: "English" },
  ar: { nativeName: "Arabic" },
  both: { nativeName: "Both" },
};
export const toastSuccessOptions = {
  position: "bottom-left",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const toastErrorOptions = {
  position: "bottom-left",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const currency = {
  value: "AED",
};
