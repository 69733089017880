import { Box, Button, IconButton, Typography } from "@mui/material";
import { React, useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import ingredientApi from "../api/ingredientApi";
import foodApi from "../api/foodApi";
import recipeApi from "../api/recipeApi";

const LiveSearchFilter = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState();
  const [ingredientDetails, setIngredientDetails] = useState();
  const [ingredientsDetails, setIngredientsDetails] = useState([]);
  const [showServingUnit, setShowServingUnit] = useState();
  const [servingUnit, setServingUnit] = useState();
  const [qty, setQty] = useState();
  const [ingredientOrSubRecipe, setingredientOrSubRecipe] =
    useState("ingredientAddType");

  const fetchRecipes = async (query) => {
    if (query === "" || query === undefined) {
      setShowServingUnit(false);
      return;
    }
    setOptions([]);
    setShowServingUnit(false);
    try {
      const requestOptions = {
        page: 1,
        limit: 100,
        query: query,
      };
      const res = await foodApi.searchItem(
        requestOptions.page,
        requestOptions.limit,
        requestOptions.query
      );
      var updatedOptions = res.foods.map((p, index) => {
        return {
          name: p.description,
          index: p.fdcId,
          id: p.fdcId,
        };
      });

      res.recipes.map((p, index) => {
        if (p[0].title !== props.sendRecipeItem.title)
          updatedOptions.push({
            name: p[0].title,
            id: p[0].id,
            index: p[0].id,
            type: "subrecipe",
          });
      });
      setOptions(updatedOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const onSelect = async (e, selectedOption) => {
    // console.log(boardId)
    setSelectedIngredient(selectedOption);
    e.target.value = "";
    // console.log(selectedOption)
    if (selectedOption.type !== "subrecipe") {
      try {
        const requestOptions = {
          fdcId: selectedOption.id,
        };
        const res = await foodApi.getFoodItem(requestOptions.fdcId);
        //console.log(res)
        let p = res;
        var recipeDetails = {
          name: p.description,
          index: p.fdcId,
          id: p.fdcId,
          fdcId: p.fdcId,
          foodNutrients: p.foodNutrients,
          foodPortions: p.foodPortions,
        };
        var GramMeasure = {
          gramWeight: 1.0,
        };
        if (recipeDetails.foodPortions === undefined)
          recipeDetails.foodPortions = [];
        recipeDetails.foodPortions.unshift(GramMeasure);

        setIngredientDetails(recipeDetails);

        setShowServingUnit(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      setingredientOrSubRecipe("subrecipe");
      try {
        const res = await recipeApi.getRecipeIngredients(
          props.boardId,
          selectedOption.id
        );
        // console.log(res)

        var tmpIngredientsRows = [];
        res.forEach((element) => {
          let p = element;
          var recipeDetails = {
            name: p.name,
            index: p.fdcId,
            id: p.fdcId,
            fdcId: p.fdcId,
            foodNutrients: [...p.nutriFacts[0].foodNutrients],
            serving_unit: p.serving_unit,
            qty: p.qty,
            subrecipeid: p.recipe,
          };
          tmpIngredientsRows.push(recipeDetails);
        });

        setIngredientsDetails(tmpIngredientsRows);
        setShowServingUnit(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (props.addIngredientToTable?.name) {
      addIngredientToTable(
        props.addIngredientToTable.name,
        "",
        props.addIngredientToTable.serving_unit,
        props.addIngredientToTable.qty,
        5,
        0.0,
        false
      );
    }
  }, [props.addIngredientToTable]);

  const addIngredientToTable = async (
    name,
    subRecipe,
    servingUnit,
    qty,
    totalQty,
    cost,
    totalCost,
    createOption
  ) => {
    //2415
    if (ingredientOrSubRecipe !== "subrecipe") {
      try {
        if (createOption === true) {
          const res = await ingredientApi.create(
            props.boardId,
            props.sendRecipeItem.id,
            {
              ingredientId: selectedIngredient.id,
              recipeId: props.sendRecipeItem.id,
              name: name,
              serving_unit: servingUnit,
              qty: qty,
            }
          );
          res.fdcId = ingredientDetails.id;
        }

        totalQty =
          parseFloat(servingUnit.match(/\((.*)\)/).pop()) * parseFloat(qty);
        props.newRow([name, subRecipe, servingUnit, qty, totalQty]);
        props.recipeItem([]);
        const ele = autoC.current.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0];
        if (ele) ele.click();
      } catch (err) {
        console.log(err);
      }
    } else {
      ingredientsDetails.forEach(async (element) => {
        // console.log(element)
        try {
          if (createOption === true) {
            const res = await ingredientApi.create(
              props.boardId,
              props.sendRecipeItem.id,
              {
                ingredientId: element.id,
                recipeId: props.sendRecipeItem.id,
                name: element.name,
                serving_unit: element.serving_unit,
                qty: element.qty,
                subrecipeid: element.subrecipeid,
              }
            );
            res.fdcId = element.id;
          }
          totalQty =
            parseFloat(element.serving_unit.match(/\((.*)\)/).pop()) *
            parseFloat(qty);
          props.newRow([
            element.name,
            element.subRecipe,
            element.serving_unit,
            element.qty,
            totalQty,
          ]);

          props.updateTables([]);
          setingredientOrSubRecipe("");
          const ele = autoC.current.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[0];
          if (ele) ele.click();
        } catch (err) {
          console.log(err);
        }
      });
    }
  };
  const autoC = useRef(null);

  return (
    <>
      <Typography variant="h6" fontWeight="300">
        Ingredients / Sub Recipe
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            ref={autoC}
            getOptionLabel={(options) => `${options.name}`}
            disablePortal
            id="ignredients-combobox"
            options={options.map((option) => option)}
            // filterOptions={(options, state) => options}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onInputChange={(e) => {
              fetchRecipes(e.target.value);
            }}
            onChange={(e, selectedOption) => {
              onSelect(e, selectedOption);
            }}
            // disableClearable
            renderInput={(params) => {
              return <TextField {...params} label=""></TextField>;
            }}
            renderOption={(props, options) => (
              <Box component="li" {...props} key={options.index}>
                {options.name}
              </Box>
            )}
          />
        </Grid>
        {showServingUnit ? (
          <>
            {ingredientOrSubRecipe !== "subrecipe" ? (
              <>
                <Grid item xs={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="servingunitLabel">Serving Unit</InputLabel>
                    <Select
                      labelId="servingunitLabel"
                      id="servingunit"
                      label="Serving Unit"
                      onChange={(e) => {
                        setServingUnit(
                          e.target.value === undefined ? "" : e.target.value
                        );
                      }}
                      defaultValue=""
                    >
                      {ingredientDetails?.foodPortions?.map((keys, index) => (
                        <MenuItem
                          value={`${
                            keys.portionDescription !== undefined
                              ? keys.portionDescription
                              : ""
                          }(${keys?.gramWeight}) Gm`}
                          key={index}
                        >
                          {`${
                            keys.portionDescription !== undefined
                              ? keys.portionDescription
                              : ""
                          }(${keys?.gramWeight}) Gm`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    size="small"
                    type="number"
                    label="Quantity"
                    id="quantity"
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={(e) => setQty(e.target.value)}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={1}>
              <Button
                color="error"
                onClick={() => {
                  if (ingredientOrSubRecipe !== "subrecipe")
                    addIngredientToTable(
                      ingredientDetails.name,
                      " ",
                      servingUnit,
                      qty,
                      5,
                      0.0,
                      0.0,
                      true
                    );
                  else
                    addIngredientToTable(" ", " ", " ", 0, 0, 0.0, 0.0, true);
                }}
              >
                Add
              </Button>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default LiveSearchFilter;
