import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
  Card,
  Tooltip,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import sectionApi from "../../api/sectionApi";
import recipeApi from "../../api/recipeApi";
import RecipeModal from "./RecipeModal";
import { toast } from "react-toastify";
import { toastErrorOptions, toastSuccessOptions } from "../../utils/constVals";
import Grow from "@mui/material/Grow";
import PieChartSharpIcon from "@mui/icons-material/PieChartSharp";
import PictureAsPdfSharpIcon from "@mui/icons-material/PictureAsPdfSharp";
import ReceiptSharpIcon from "@mui/icons-material/ReceiptSharp";
import QrCode2SharpIcon from "@mui/icons-material/QrCode2Sharp";
import CategorySharpIcon from "@mui/icons-material/CategorySharp";
import QrCodeSharpIcon from "@mui/icons-material/QrCodeSharp";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NutritionsFactsLabelVertical from "../Labels/NutritionsFactsLabelVertical";
import LabelWithIndicators from "../Labels/LabelWithIndicators";
import QrModal from "../QrModal";
import DonutModal from "../DonutModal";
import RecipeCartModal from "../RecipeCartModal";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { alignProperty } from "@mui/material/styles/cssUtils";

let timer;
const timeout = 500;

const iconsBarStyles = {
  marginLeft: "5px",
  "&:hover": { color: "red", transition: "0.3s" },
  margin: "7px",
};

const Kanban = (props) => {
  const boardId = props.boardId;
  const [data, setData] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(undefined);
  const [isShown, setIsShown] = useState(false);
  const [isActiveItem, setIsActiveItem] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  const onDragEnd = async ({ source, destination }) => {
    if (!destination) return;

    const sourceColIndex = data.findIndex((e) => e.id === source.droppableId);
    const destinationColIndex = data.findIndex(
      (e) => e.id === destination.droppableId
    );
    const sourceCol = data[sourceColIndex];
    const destinationCol = data[destinationColIndex];

    const sourceSectionId = sourceCol.id;
    const destinationSectionId = destinationCol.id;

    const sourceRecipes = [...sourceCol.recipes];
    const destinationRecipes = [...destinationCol.recipes];

    if (source.droppableId !== destination.droppableId) {
      const [removed] = sourceRecipes.splice(source.index, 1);
      destinationRecipes.splice(destination.index, 0, removed);
      data[sourceColIndex].recipes = sourceRecipes;
      data[destinationColIndex].recipes = destinationRecipes;
    } else {
      const [removed] = destinationRecipes.splice(source.index, 1);
      destinationRecipes.splice(destination.index, 0, removed);
      data[destinationColIndex].recipes = destinationRecipes;
    }

    try {
      await recipeApi.updatePosition(boardId, {
        resourcesList: sourceRecipes,
        destinationList: destinationRecipes,
        resourceSectionId: sourceSectionId,
        destinationSectionId: destinationSectionId,
      });
      setData(data);
      toast.success("Recipe Category Updated", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const createSection = async () => {
    try {
      const section = await sectionApi.create(boardId);
      setData([...data, section]);
      toast.success("Category Created", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const deleteSection = async (sectionId) => {
    try {
      await sectionApi.delete(boardId, sectionId);
      const newData = [...data].filter((e) => e.id !== sectionId);
      setData(newData);
      toast.info("Category Deleted", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const updateSectionTitle = async (e, sectionId) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    const newData = [...data];
    const index = newData.findIndex((e) => e.id === sectionId);
    newData[index].title = newTitle;
    setData(newData);
    timer = setTimeout(async () => {
      try {
        await sectionApi.update(boardId, sectionId, { title: newTitle });
        toast.success("Category Title Changed", toastSuccessOptions);
      } catch (err) {
        alert("error here");
      }
    }, timeout);
  };

  const createRecipe = async (sectionId) => {
    try {
      const recipe = await recipeApi.create(boardId, { sectionId });

      const newData = [...data];
      const index = newData.findIndex((e) => e.id === sectionId);
      newData[index].recipes.unshift(recipe);

      setData(newData);
      toast.success("New Recipe Created", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const DuplicateRecipe = async (sectionId, recipeItem) => {
    try {
      const recipe = await recipeApi.duplicate(boardId, {
        sectionId,
        recipeItem,
      });

      const newData = [...data];
      const index = newData.findIndex((e) => e.id === sectionId);
      newData[index].recipes.unshift(recipe);

      setData(newData);
      toast.success("Recipe Duplicated", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const onUpdateRecipe = (recipe) => {
    const newData = [...data];
    const sectionIndex = newData.findIndex((e) => e.id === recipe.section.id);
    const recipeIndex = newData[sectionIndex].recipes.findIndex(
      (e) => e.id === recipe.id
    );
    newData[sectionIndex].recipes[recipeIndex] = recipe;
    setData(newData);
  };

  const onDeleteRecipe = async (recipe) => {
    const recipeItem = recipe;
    try {
      await recipeApi.delete(boardId, recipe.id);
      props.onDelete(recipe);
      //setRecipe(undefined)
      toast.info("Recipe Deleted", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
    onUpdateRecipe(recipeItem);
    // alert(recipe.section.id);
    const newData = [...data];
    const sectionIndex = newData.findIndex((e) => e.id === recipe.section.id);
    const recipeIndex = newData[sectionIndex].recipes.findIndex(
      (e) => e.id === recipe.id
    );
    newData[sectionIndex].recipes.splice(recipeIndex, 1);
    setData(newData);
    toast.info("Recipe Deleted", toastSuccessOptions);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNutritionFactsLabelModal, setOpenNutritionFactsLabelModal] =
    useState(false);
  const [
    nutritionFactsLabelModalRecipeId,
    setNutritionFactsLabelModalRecipeId,
  ] = useState("");

  const handleOpenNutritionFactsLabelModal = (e) => {
    setNutritionFactsLabelModalRecipeId(e.id);
    setOpenNutritionFactsLabelModal(true);
  };
  const handleCloseNutritionFactsLabelModal = () => {
    setNutritionFactsLabelModalRecipeId("");
    setOpenNutritionFactsLabelModal(false);
  };

  const [
    nutritionIndicatorsModalRecipeId,
    setNutritionIndicatorsModalRecipeId,
  ] = useState("");

  const [openNutritionIndicatorsModal, setOpenNutritionIndicatorsModal] =
    useState(false);
  const handleOpenNutritionIndicatorsModal = (e) => {
    setNutritionIndicatorsModalRecipeId(e.id);
    setOpenNutritionIndicatorsModal(true);
  };
  const handleCloseNutritionIndicatorsModal = () => {
    setNutritionIndicatorsModalRecipeId("");
    setOpenNutritionIndicatorsModal(false);
  };

  const [QrModalRecipeId, setQrModalRecipeId] = useState("");

  const [openQrModal, setopenQrModal] = useState(false);
  const handleOpenQrModal = (e) => {
    setQrModalRecipeId(e.id);
    setopenQrModal(true);
  };
  const handleCloseQrModal = () => {
    setQrModalRecipeId("");
    setopenQrModal(false);
  };

  const [donutChartModalRecipe, setdonutChartModalRecipe] = useState(undefined);

  const [opendonutChartModal, setopendonutChartModal] = useState(false);
  const handleOpendonutChartModal = (recipe) => {
    setdonutChartModalRecipe(recipe);
    setopendonutChartModal(true);
  };
  const handleClosedonutChartModal = () => {
    setdonutChartModalRecipe(undefined);
    setopendonutChartModal(false);
  };

  const [recipeCartModalRecipe, setrecipeCartModalRecipe] = useState(undefined);

  const [openRecipeCartModal, setrecipeCartModal] = useState(false);
  const handleOpenRecipeCartModal = (recipe) => {
    setrecipeCartModalRecipe(recipe);
    setrecipeCartModal(true);
  };
  const handleCloseRecipeCartModal = () => {
    setrecipeCartModalRecipe(undefined);
    setrecipeCartModal(false);
  };
  const [filterSearch, setFilterSearch] = useState("");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={createSection}>Add Recipes Category</Button>
        <Typography variant="body2" fontWeight="700" className="ml-auto pr-10">
          {data.length} Sections
        </Typography>
        <div className="relative rounded-md shadow-sm pb-2 ">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg
              className="h-5 w-5 text-gray-400 mb-2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
          <input
            className="form-input py-2 pl-10 rounded-md border-gray-300 border-2 focus:outline-none focus:border-lime-500 w-72 "
            type="search"
            placeholder="Search..."
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
          />
        </div>
      </Box>
      <Divider sx={{ margin: "10p 0" }} />

      {loading ? (
        <Stack spacing={3}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="circular" width={100} height={100} />
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"} />
          <Skeleton variant="rounded" width={"100%"} height={"20vh"} />
        </Stack>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: "calc(100vw - 400px)",
              overflow: "auto",
              flexWrap: "wrap",
            }}
          >
            {data.map((section) => (
              <div key={section.id} style={{ margin: "5px" }}>
                <Droppable key={section.id} droppableId={section.id}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        width: "310px",
                        padding: "10px",
                        margin: "10px",
                        backgroundColor: "white",
                        opacity: "0.85",
                        boxShadow: 10,
                        borderRadius: "0px 30px 0px 30px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          value={section.title}
                          onChange={(e) => updateSectionTitle(e, section.id)}
                          placeholder="Untitled"
                          variant="outlined"
                          sx={{
                            flexGrow: 1,
                            "& .MuiOutlinedInput-input": { padding: 1 },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "unset",
                            },
                            "& .MuiOutlinedInput-root": { fontSize: "0.8rem" },
                          }}
                        />
                        <IconButton
                          variant="outlined"
                          size="small"
                          sx={{
                            color: "gray",
                            "&:hover": { color: "green" },
                          }}
                          onClick={() => createRecipe(section.id)}
                        >
                          <AddOutlinedIcon />
                        </IconButton>

                        <IconButton
                          variant="outlined"
                          size="small"
                          sx={{
                            color: "gray",
                            "&:hover": { color: "red" },
                          }}
                          onClick={() => deleteSection(section.id)}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Box>
                      {/* Recipes */}
                      {section.recipes.map((recipe, index) => (
                        <Draggable
                          key={recipe.id}
                          draggableId={recipe.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                padding: "10px",
                                marginBottom: "10px",
                                cursor: snapshot.isDragging
                                  ? "grab"
                                  : "pointer!important",
                                boxShadow: 1,
                                borderRadius: "0px 20px 0px 20px",
                                "&:hover": {
                                  backgroundColor: "#E5E4E2",
                                  transition: "0.5s",
                                  boxShadow: 10,
                                },
                              }}
                              className={`${
                                recipe.title
                                  .toLowerCase()
                                  .includes(filterSearch.toLowerCase())
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => setSelectedRecipe(recipe)}
                              >
                                <Typography variant="body2">
                                  {recipe.title === ""
                                    ? "Untitled"
                                    : recipe.title}
                                </Typography>
                                <GridViewOutlinedIcon
                                  onMouseEnter={(e) => {
                                    setIsActiveItem(recipe.id);
                                    setIsShown(true);
                                  }}
                                  aria-owns={
                                    anchorEl ? "simple-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseOver={(e) =>
                                    setAnchorEl(e.currentTarget)
                                  }
                                  sx={{
                                    color: "gray ",
                                  }}
                                ></GridViewOutlinedIcon>
                              </Box>

                              {isShown && isActiveItem === recipe.id && (
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                    onMouseLeave: () => {
                                      setAnchorEl(null);
                                    },
                                  }}
                                  sx={{
                                    "& .MuiList-root": {
                                      borderRadius: 10,
                                      padding: 0,
                                    },
                                    "& .MuiPaper-root": {
                                      borderRadius: 10,
                                      padding: 0,
                                    },
                                    "& .MuiButtonBase-root": {
                                      borderRadius: 10,
                                      backgroundColor: "white !important",
                                    },
                                  }}
                                  onClick={(e) => {
                                    setAnchorEl(null);
                                  }}
                                >
                                  <MenuItem>
                                    <Grow
                                      in={isShown}
                                      {...(isActiveItem
                                        ? { timeout: 1000 }
                                        : {})}
                                    >
                                      <Box
                                        sx={{
                                          marginTop: "10px",
                                          flexWrap: "wrap",
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "120px",
                                        }}
                                      >
                                        <Tooltip
                                          title="Analysis"
                                          placement="left"
                                          arrow
                                        >
                                          <PieChartSharpIcon
                                            onClick={(e) => {
                                              setdonutChartModalRecipe(
                                                undefined
                                              );
                                              handleOpendonutChartModal(recipe);
                                            }}
                                            sx={iconsBarStyles}
                                          ></PieChartSharpIcon>
                                        </Tooltip>
                                        {/* 
                                        <Tooltip
                                          title="Download Recipe as PDF"
                                          placement="top"
                                          arrow
                                        >
                                          <PictureAsPdfSharpIcon
                                            onClick={(e) => {
                                              setSelectedRecipe();
                                              e.preventDefault();

                                              alert(recipe.id);
                                            }}
                                            sx={iconsBarStyles}
                                          ></PictureAsPdfSharpIcon>
                                        </Tooltip> */}

                                        <Tooltip
                                          title="Download Recipe as Label"
                                          placement="top"
                                          arrow
                                        >
                                          <ReceiptSharpIcon
                                            onClick={(e) => {
                                              handleOpenRecipeCartModal(recipe);
                                            }}
                                            sx={iconsBarStyles}
                                          ></ReceiptSharpIcon>
                                        </Tooltip>
                                        <Tooltip
                                          title="Food Item Label QR"
                                          placement="right"
                                          arrow
                                        >
                                          <QrCode2SharpIcon
                                            onClick={(e) => {
                                              handleOpenQrModal(recipe);
                                            }}
                                            sx={iconsBarStyles}
                                          ></QrCode2SharpIcon>
                                        </Tooltip>
                                        <Tooltip
                                          title="Nutritional Labels"
                                          placement="left"
                                          arrow
                                        >
                                          <CategorySharpIcon
                                            onClick={(e) => {
                                              e.preventDefault();

                                              handleOpenNutritionIndicatorsModal(
                                                recipe
                                              );
                                            }}
                                            sx={iconsBarStyles}
                                          ></CategorySharpIcon>
                                        </Tooltip>

                                        <Tooltip
                                          title="Nutrional Facts Label"
                                          placement="bottom"
                                          arrow
                                        >
                                          <QrCodeSharpIcon
                                            onClick={(e) => {
                                              e.preventDefault();
                                              // setSelectedRecipe();

                                              handleOpenNutritionFactsLabelModal(
                                                recipe
                                              );

                                              // alert(recipe.id);
                                            }}
                                            sx={iconsBarStyles}
                                          ></QrCodeSharpIcon>
                                        </Tooltip>

                                        <Tooltip
                                          title="Duplicate Recipe"
                                          placement="right-end"
                                          arrow
                                        >
                                          <ContentCopySharpIcon
                                            onClick={(e) => {
                                              setSelectedRecipe();
                                              e.preventDefault();
                                              DuplicateRecipe(
                                                section.id,
                                                recipe
                                              );
                                            }}
                                            sx={iconsBarStyles}
                                          ></ContentCopySharpIcon>
                                        </Tooltip>

                                        <Tooltip
                                          title="Edit Recipe"
                                          placement="bottom"
                                          arrow
                                        >
                                          <EditSharpIcon
                                            onClick={() =>
                                              setSelectedRecipe(recipe)
                                            }
                                            sx={iconsBarStyles}
                                          ></EditSharpIcon>
                                        </Tooltip>
                                        <Tooltip
                                          title="Delete Recipe"
                                          placement="right"
                                          arrow
                                        >
                                          <DeleteOutlineSharpIcon
                                            onClick={(e) => {
                                              // setSelectedRecipe();
                                              e.preventDefault();
                                              recipe.section.id = section.id;
                                              onDeleteRecipe(recipe);
                                            }}
                                            sx={iconsBarStyles}
                                          ></DeleteOutlineSharpIcon>
                                        </Tooltip>
                                      </Box>
                                    </Grow>
                                  </MenuItem>
                                </Menu>
                              )}
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </div>
            ))}
          </Box>
        </DragDropContext>
      )}
      <RecipeModal
        recipe={selectedRecipe}
        boardId={boardId}
        onClose={() => {
          setSelectedRecipe(undefined);
        }}
        onUpdate={onUpdateRecipe}
        closeModalBtn={(e) => setSelectedRecipe(undefined)}
      />
      <NutritionsFactsLabelVertical
        recipeId={nutritionFactsLabelModalRecipeId}
        open={openNutritionFactsLabelModal}
        onClose={handleCloseNutritionFactsLabelModal}
      />
      <LabelWithIndicators
        recipeId={nutritionIndicatorsModalRecipeId}
        open={openNutritionIndicatorsModal}
        onClose={handleCloseNutritionIndicatorsModal}
      />
      <QrModal
        recipeId={QrModalRecipeId}
        open={openQrModal}
        onClose={handleCloseQrModal}
      ></QrModal>
      <DonutModal
        recipe={donutChartModalRecipe}
        open={opendonutChartModal}
        onClose={handleClosedonutChartModal}
      ></DonutModal>
      <RecipeCartModal
        recipeId={recipeCartModalRecipe}
        open={openRecipeCartModal}
        onClose={handleCloseRecipeCartModal}
        boardId={boardId}
      ></RecipeCartModal>
    </>
  );
};

export default Kanban;
