import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

const TotalCard = ({ totalNumber = 0, title = "" }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter !== totalNumber) {
      const timeout = setTimeout(() => {
        setCounter(counter + 1);
      }, 0.001);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [counter, totalNumber]);

  return (
    <>
      <Card
        sx={{
          background: "#80cd29",
          color: "white",
          borderRadius: "0 20px 0 20px !important",
          minHeight: 200,
        }}
        className="shadow-2xl shadow-lime-700  drop-shadow-2xl"
      >
        <CardContent
          sx={{
            display: "grid",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Typography variant="h2" mt={2}>
            +{counter}
          </Typography>
          <Typography variant="h4" mt={2}>
            {title}
          </Typography>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </>
  );
};

export default TotalCard;
