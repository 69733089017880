import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, TextField } from "@mui/material";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import boardApi from "../api/boardApi";
import { setBoards } from "../redux/features/boardSlice";
import { setFavouriteList } from "../redux/features/favouriteSlice";
import Kanban from "../components/common/Kanban";
import { toast } from "react-toastify";
import { toastSuccessOptions, toastErrorOptions } from "../utils/constVals";
// import { setCurrentBoard } from '../redux/features/currentBoardSlice'
let timer;
const timeout = 500;

const Board = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boardId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sections, setSections] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);
  const [loading, setLoading] = useState(false);
  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  useEffect(() => {
    const getBoard = async () => {
      try {
        setLoading(true);
        const res = await boardApi.getOne(boardId);
        setTitle(res.title);
        setDescription(res.description);
        setSections(res.sections);
        setIsFavourite(res.favourite);
        setLoading(false);
      } catch (err) {
        toast.error(err, toastErrorOptions);
        setLoading(false);
      }
    };
    getBoard();
  }, [boardId]);

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    setTitle(newTitle);

    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], title: newTitle };

    if (isFavourite) {
      let tempFavourite = [...favouriteList];
      const favouriteIndex = temp.findIndex((e) => e.id === boardId);
      tempFavourite[favouriteIndex] = {
        ...tempFavourite[favouriteIndex],
        title: newTitle,
      };
      dispatch(setFavouriteList(tempFavourite));
    }

    dispatch(setBoards(temp));

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { title: newTitle });
        toast.success("Title Updated", toastSuccessOptions);
      } catch (err) {
        toast.success(err, toastErrorOptions);
      }
    }, timeout);
  };

  const updateDescription = async (e) => {
    clearTimeout(timer);
    const newDescription = e.target.value;
    setDescription(newDescription);

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { description: newDescription });
        toast.success("Description Updated", toastSuccessOptions);
      } catch (err) {
        toast.success(err, toastErrorOptions);
      }
    }, timeout);
  };

  const addFavourite = async () => {
    try {
      const board = await boardApi.update(boardId, { favourite: !isFavourite });
      if (!isFavourite) {
        toast.success("Added to Favourites", toastSuccessOptions);
      } else {
        toast.success("Removed From Favourites", toastSuccessOptions);
      }
      let newFavouriteList = [...favouriteList];
      if (isFavourite) {
        newFavouriteList = favouriteList.filter((e) => e.id !== boardId);
      } else {
        newFavouriteList.unshift(board);
      }
      dispatch(setFavouriteList(newFavouriteList));
      setIsFavourite(!isFavourite);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  const deleteBoard = async () => {
    try {
      await boardApi.delete(boardId);
      if (isFavourite) {
        const newFavouriteList = favouriteList.filter((e) => e.id !== boardId);
        dispatch(setFavouriteList(newFavouriteList));
      }
      const newList = boards.filter((e) => e.id !== boardId);
      if (newList.length === 0) {
        navigate("/app/boards");
      } else {
        navigate(`/app/boards/${newList[0].id}`);
      }
      dispatch(setBoards(newList));
      toast.info("Main Category Deleted", toastSuccessOptions);
    } catch (err) {
      toast.error(err, toastErrorOptions);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "10px 10px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              value={title}
              placeholder="Untitled"
              variant="outlined"
              onChange={updateTitle}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-input": { padding: 0 },
                "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
                "& .MuiOutlinedInput-root": {
                  fontSize: "2rem",
                  fontWeight: "700",
                },
              }}
            />
            <IconButton
              variant="outlined"
              onClick={addFavourite}
              sx={{ marginLeft: "50px" }}
            >
              {isFavourite ? (
                <StarOutlinedIcon color="warning" />
              ) : (
                <StarBorderOutlinedIcon />
              )}
            </IconButton>
            <IconButton variant="outlined" color="error" onClick={deleteBoard}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
          <TextField
            value={description}
            onChange={updateDescription}
            placeholder="add a descriptiom"
            variant="outlined"
            multiline
            fullWidth
            sx={{
              "& .MuiOutlinedInput-input": { padding: 0 },
              "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
              "& .MuiOutlinedInput-root": { fontSize: "0.8rem" },
            }}
          />
        </Box>

        <Box>
          {/* Kanban Board */}
          <Kanban data={sections} boardId={boardId} loading={loading} />
        </Box>
      </Box>
    </>
  );
};

export default Board;
