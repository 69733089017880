import React from "react";
import { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

import assets from "../assets";
import { Backdrop, Fade, Modal, Box, IconButton, Button } from "@mui/material";
import QRCode from "react-qr-code";
import { clientBaseUrl } from "../api/axiosClient";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "400px",

  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
  borderRadius: "100px 0px 100px 0px",
  overflow: "hidden",
};

const QrModal = (props) => {
  const [recipe, setRecipe] = useState("");

  useEffect(() => {
    setRecipe(props.recipeId);
  }, [props.recipeId]);

  const onClose = async () => {
    try {
      props.onClose();
    } catch (err) {}
  };

  return (
    <>
      <Modal
        open={props.open !== false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={onClose}
      >
        <Fade in={props.open !== false}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                widht: "100%",
              }}
            >
              <IconButton
                variant="outlined"
                color="error"
                onClick={(e) => {
                  props.onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }} pt={5}>
              <QRCode value={clientBaseUrl + "label-generator/" + recipe} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default QrModal;
