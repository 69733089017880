import { Box, Button, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import authApi from "../api/authApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { toastSuccessOptions } from "../utils/constVals";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { assetBaseUrl, baseUrl } from "../api/axiosClient";
const Profile = () => {
  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPasswordErrText, setCurrentPasswordErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCurrentPasswordErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");

    const data = new FormData(e.target);
    const username = user.username;
    const currentpassword = data.get("currentpassword").trim();
    const password = data.get("password").trim();
    const confirmPassword = data.get("confirmPassword").trim();

    let err = false;

    if (currentpassword === "") {
      err = true;
      setCurrentPasswordErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }
    if (password !== confirmPassword) {
      err = true;
      setConfirmPasswordErrText("Confirm password not match");
    }

    if (err) return;

    setLoading(true);
    try {
      await authApi.resetpassword({
        username,
        currentpassword,
        password,
        confirmPassword,
      });
      setLoading(false);
      // localStorage.setItem('token', res.token)
      toast.success("Password Changed", toastSuccessOptions);
      navigate("app/");
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          alert("username not found");
        }
        if (e.param === "currentPassword") {
          setCurrentPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setConfirmPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  const handleChangeImage = async (e) => {
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    // console.log(formData);

    try {
      const res = await authApi.updatePhoto(formData);
      // console.log(res.data);
      setAvatarImage(assetBaseUrl + "/profilesPhotos/" + res.data);
    } catch (err) {
      console.log(err + " Can't upload image");
    }
  };

  useEffect(() => {
    const getProfileImage = async () => {
      try {
        const avatarPhoto = await authApi.getPhoto();
        //  console.log(avatarPhoto);
        if (avatarPhoto === "profilesPhotos/undefined") {
          const avatarDefault = "avatar.png";

          setAvatarImage(assetBaseUrl + "profilesPhotos/" + avatarDefault);

          return;
        }
        setAvatarImage(assetBaseUrl + avatarPhoto);
      } catch (err) {
        console.log(err);
      }
    };
    getProfileImage();
  }, []);

  const [avatarImage, setAvatarImage] = useState("");
  return (
    <>
      <div className="w-full  ">
        <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit} noValidate>
          <Box className="flex justify-center text-start">
            <div className="flex flex-col justify-center items-center">
              <Avatar
                src={avatarImage}
                sx={{ width: 250, height: 250 }}
                className="shadow-xl shadow-black"
              />
              <Box className="text-left mt-10 flex flex-col ">
                <Typography
                  variant="h6"
                  color="success"
                  className="text-center"
                >
                  Change Password
                </Typography>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="currentpassword"
                  label="Current Password"
                  name="currentpassword"
                  type="currentpassword"
                  disabled={loading}
                  error={currentPasswordErrText !== ""}
                  helperText={currentPasswordErrText}
                  sx={{ width: "300px" }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  disabled={loading}
                  error={passwordErrText !== ""}
                  helperText={passwordErrText}
                  sx={{ width: "300px" }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  disabled={loading}
                  error={confirmPasswordErrText !== ""}
                  helperText={confirmPasswordErrText}
                  sx={{ width: "300px" }}
                />
                <LoadingButton
                  sx={{ mt: 3, mb: 2, width: "300px" }}
                  variant="outlined"
                  color="success"
                  type="submit"
                  loading={loading}
                >
                  Reset Password
                </LoadingButton>
              </Box>
            </div>
            <div className="flex flex-col ml-5 mt-16">
              <div>
                <Typography
                  variant="h4"
                  color="success"
                  className="whitespace-nowrap"
                >
                  Welcome {user.name}
                </Typography>
                <Button
                  component="label"
                  onChange={async (e) => {
                    handleChangeImage(e);
                  }}
                  className="w-[200px] rounded-3xl ml-5 bg-transparent text-black hover:text-red-700 transition duration-300 "
                >
                  Update Photo
                  <input type="file" accept=".jpg, .jpeg, .png" hidden />
                </Button>
              </div>
              <div className="flex flex-col whitespace-nowrap">
                <div className="flex space-x-2 ">
                  <p className="text-md font-[800] ">Username: </p>
                  <p className="whitespace-nowrap">{user.username}</p>
                </div>
                <div className="flex space-x-2 ">
                  <p className="text-md font-[800]">Website: </p>
                  <p>{user.website}</p>
                </div>
                <div className="flex space-x-2 ">
                  <p className="text-md font-[800]">Account Status: </p>
                  <p>Active</p>
                </div>
                <div className="flex space-x-2">
                  <p className="text-md font-[800]">Expiry Date:</p>
                  <p>{user.expirydate?.substring(0, 10)}</p>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Profile;
