export const ingredientTableHeaders = [
  "S/N",
  "Name",
  "Sub Recipe",
  "Serving Unit",
  "Qty.",
  "Total Qty.",
  "Cost/Gram",
  "Total Cost",
  "id",
];

export const nutritionValuesHeaders = [
  "S/N",
  "energy",
  "carbohydrate, by difference",
  "protein",
  "total fat (nlea)",

  "fiber, total dietary",

  "cryptoxanthin, beta",
  "lycopene",
  // "tocopherol, delta",
  // "tocotrienol, gamma",
  // "tocotrienol, delta",
  "vitamin c, total ascorbic acid",
  "thiamin",
  "riboflavin",
  "folate, total",
  "vitamin k (dihydrophylloquinone)",
  "vitamin k (phylloquinone)",
  "fatty acids, total trans",
  "fatty acids, total saturated",
  // "sfa 8:0",
  // "sfa 12:0",
  // "sfa 14:0",
  // "pufa 22:6 n-3 (dha)",
  // "sfa 22:0",
  // "pufa 20:5 n-3 (epa)",
  // "pufa 22:5 n-3 (dpa)",
  // "sfa 17:0",
  // "sfa 24:0",
  // "tfa 16:1 t",
  // "mufa 24:1 c",
  // "mufa 18:1 c",
  // "pufa 18:2 n-6 c,c",
  // "mufa 22:1 c",
  // "mufa 17:1",
  "fatty acids, total trans-monoenoic",
  // "mufa 15:1",
  // "pufa 18:3 n-3 c,c,c (ala)",
  // "pufa 20:3 n-3",
  // "pufa 22:4",
  "ash",
  "starch",
  "fructose",
  "lactose",
  // "energy",
  "galactose",
  "iron, fe",
  "magnesium, mg",
  "phosphorus, p",
  "sugars, total",
  "sugars, added",
  "sodium, na",
  "copper, cu",
  "manganese, mn",
  "vitamin a, rae",
  "carotene, beta",
  "carotene, alpha",
  // "pufa 20:4",
  // "pufa 18:4",
  "fatty acids, total monounsaturated",
  // "fatty acids, total polyunsaturated",
  // "sfa 15:0",
  // "tfa 18:1 t",
  // "tfa 22:1 t",
  // "tfa 18:2 t not further defined",
  // "pufa 18:2 clas",
  // "pufa 20:2 n-6 c,c",
  // "mufa 16:1 c",
  // "pufa 18:3 n-6 c,c,c",
  // "fatty acids, total trans-polyenoic",
  // "pufa 20:4 n-6",
  // "sfa 4:0",
  // "sfa 6:0",
  // "sfa 10:0",
  // "sfa 16:0",
  // "sfa 18:0",
  // "sfa 20:0",
  "lutein + zeaxanthin",
  "tocopherol, beta",
  "tocopherol, gamma",
  "tocotrienol, alpha",
  "tocotrienol, beta",
  "niacin",
  "pantothenic acid",
  "vitamin b-6",
  "vitamin k (menaquinone-4)",
  "total lipid (fat)",
  "sucrose",
  "glucose",
  "maltose",
  "water",
  "calcium, ca",
  "potassium, k",
  "zinc, zn",
  "selenium, se",
  "vitamin e (alpha-tocopherol)",
  "nitrogen",
  // "mufa 14:1 c",
  // "mufa 20:1 c",
  // "mufa 22:1 n-9",
  // "pufa 22:2",
  // "sfa 11:0",
  // "tfa 18:3 t",
  // "pufa 20:3 n-9",
  "choline, total",
  "choline, from glycerophosphocholine",
  "betaine",
  "choline, from sphingomyelin",
  "choline, free",
  "choline, from phosphotidyl choline",
  "choline, from phosphocholine",
  "sugars, total including nlea",
  "carbohydrate, by summation",
  "fatty acids, total trans-dienoic",
  // "mufa 17:1 c",
  // "pufa 18:2 c",
  // "pufa 18:3 c",
  // "pufa 20:3 c",
  // "pufa 20:4c",
  // "pufa 20:5c",
  // "pufa 22:5 c",
  // "pufa 22:6 c",
  // "pufa 20:2 c",
];

export const allergies = [
  "Almond",
  "Banana",
  "Black Pepper",
  "Brinjal",
  "Buckwheat",
  "Caffeine",
  "Cashew Nut",
  "Celery",
  "Cheese",
  "Citrus Fruits",
  "Cocoa",
  "Coconut",
  "Coconut Water",
  "Corn",
  "Cow Milk",
  "Protein",
  "Crab",
  "Cucumber",
  "Curd and Buttermilk",
  "Dairy Products",
  "Egg",
  "Egg Yolk",
  "Fish",
  "Food Additives",
  "Food Colors",
  "Garlic",
  "Hazelnut",
  "Kiwi",
  "Lactose",
  "Lady's Finger",
  "Lemon",
  "Litchee",
  "Macadamia Nuts",
  "MasoorDal",
  "Mushroom",
  "Mustard",
  "Peanuts",
  "Pecan",
  "Pineapple",
  "Pine Nut",
  "Pistachio",
  "Poppy Seeds",
  "PoultryMeat",
  "Pumpkin",
  "Red Chilli",
  "Red Meat",
  "Sago",
  "SeaFood",
  "Sesame Seeds",
  "Shell Fish",
  "Soy",
  "Soy Lecithin",
  "Squid",
  "Strawberry",
  "Sunflower Seed",
  "Tomato",
  "Tree Nut",
  "Turmeric",
  "Walnut",
  "Wheat",
  "Wine",
  "Yeast",
];
