import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import TotalRecipesCost from "../components/dashboard/TotalRecipesCost";
import TotalCard from "../components/dashboard/TotalCard";
import DetailsStripe from "../components/dashboard/DetailsStripe";
import ColumnLineChart from "../components/dashboard/ColumnLineChart";
import ingredientApi from "../api/ingredientApi";

const AppHome = () => {
  const user = useSelector((state) => state.user.value);

  const [ingredientsApiDetails, setIngredientsApiDetails] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [totalRecipes, setTotalRecipes] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0);
  useEffect(() => {
    // console.log(user)

    const getThem = async () => {
      try {
        const recipesIngredientsArray = await ingredientApi.getCosts(user.id);
        const recipesIngredients = recipesIngredientsArray[1];
        setIngredientsApiDetails(recipesIngredients);
        // console.log(recipesIngredients);
        let recipesSet = new Set();
        if (recipesIngredients !== undefined) {
          var totalCostSum = 0;
          for (let index = 0; index < recipesIngredients.length; index++) {
            let p = recipesIngredients[index];

            var x = 0;
            //prevent infinity numbers
            if (
              isNaN(p.cost_yield) ||
              p.cost_yield === 0 ||
              isNaN(p.cost) ||
              p.cost === 0 ||
              isNaN(p.cost_per_gram) ||
              p.cost_per_gram === 0
            ) {
              if (p.cost_yield === 0) p.cost_yield = 100;
              recipesSet.add(p.recipe);
              continue;
            }
            x =
              (p.cost / p.cost_per_gram) *
              (100 / p.cost_yield) *
              parseFloat(p.serving_unit.match(/\((.*)\)/).pop()) *
              parseFloat(p.qty);
            // console.log(x);
            totalCostSum += x;
            recipesSet.add(p.recipe);
          }
          setTotalCost(totalCostSum);
        }
        setTotalRecipes(recipesIngredientsArray[2].numberOfRecipes);
        setTotalIngredients(recipesIngredients.length);
      } catch (err) {
        console.log(err);
      }
    };
    getThem();
  }, [user]);

  return (
    <>
      <Grid
        container
        spacing={2}
        p={3}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={3}>
          <TotalRecipesCost totalRecipesCost={totalCost} />
        </Grid>

        <Grid item xs={3}>
          <TotalCard totalNumber={totalRecipes} title="Recipes" />
        </Grid>

        <Grid item xs={3}>
          <TotalCard totalNumber={totalIngredients} title="Ingredients" />
        </Grid>
        {/* TODO */}

        {/* <Grid item xs={3}>
          <DetailsStripe
            title="Latest Recipe"
            details="Latest Recipe"
            href="/boards"
          ></DetailsStripe>
          <br></br>
          <DetailsStripe
            title="Latest Recipe"
            details="Latest Recipe"
            href="/boards"
          ></DetailsStripe>
        </Grid> */}
      </Grid>
      <Divider />

      <Grid container spacing={2} p={3} pt={2}>
        <Grid item xs={12}>
          <ColumnLineChart recipesDetails={ingredientsApiDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default AppHome;
