import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CustomTable = (props) => {
  const [dataGridHeaders, setDataGridHeaders] = useState([]);
  const [dataGridRows, setDataGridRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //create data headers
  useEffect(() => {
    setIsLoading(true);
    setDataGridHeaders([]);
    setDataGridRows([]);
    const getColumnWidth = (cellIndex) => {
      if (cellIndex === 0) return 70;
      if (cellIndex === 3) return 170;

      if (cellIndex === 1) return 350;
      if (cellIndex !== 1) return 95;
    };
    Array.from(props.headers).map((cell, cellIndex) =>
      setDataGridHeaders((dataGridHeaders) => [
        ...dataGridHeaders,
        {
          field: cellIndex.toString(),
          headerName: cell.toString(),
          width: getColumnWidth(cellIndex),

          editable: props.headers.length < 12 && cellIndex === 4 ? true : false,
          headerAlign: "center",
          align: "center",
        },
      ])
    );
    if (props.headers.length < 10)
      setDataGridHeaders((dataGridHeaders) => [
        ...dataGridHeaders,
        {
          field: "action",
          headerName: "Action",
          sortable: false,
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation();

              const api = params.api;
              const thisRow = {};

              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  (c) =>
                    (thisRow[c.field] = params.getValue(params.id, c.field))
                );

              var ingredientIdFromHiddenField = JSON.stringify(
                thisRow["8"],
                null,
                4
              );
              //remove " " double quotations
              ingredientIdFromHiddenField =
                ingredientIdFromHiddenField.substring(
                  1,
                  ingredientIdFromHiddenField.length - 1
                );
              props.deleteOneIngredient(ingredientIdFromHiddenField);
              // return alert(ingredientIdFromHiddenField)
            };
            if (params.row[1] === undefined) return <Box> </Box>;

            return <Button onClick={onClick}>Remove</Button>;
          },
        },
        ,
      ]);
  }, [props.headers]);

  //create data rows
  useEffect(() => {
    var finalAllRows = [];
    var rowId = 0;
    var totalSum = 0;
    props.rows.map((row, index) => {
      var dd = { id: `${rowId++}` };
      row.map((element, cellIndex) => {
        dd[cellIndex + 1] = element;
        if (cellIndex + 1 === 5) {
          totalSum += dd[cellIndex + 1];
        }
      });
      //add serial number
      dd[0] = index + 1;

      finalAllRows.push(dd);
    });
    if (dataGridHeaders.length > 12)
      finalAllRows[finalAllRows.length - 1][0] = `Total`;
    setDataGridRows(finalAllRows);
    setTotal(totalSum);
    setIsLoading(false);
  }, [props.rows]);

  var CustomFooterTotalComponent = () => {
    //<10 to show the custom footer only on the ingredients table
    return;
    // <Box sx={{ padding: "5px", display: "flex" }}>
    //   <b> Total Weight:&nbsp;</b> {total} (Gram)
    // </Box>
  };

  const editOneIngredient = (ingredientId, ingredientSN, newQty) => {
    props.editOneIngredient(ingredientId, ingredientSN, newQty);
  };
  return (
    <>
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={dataGridRows}
          columns={dataGridHeaders}
          components={{
            Toolbar: GridToolbar,
            Footer: CustomFooterTotalComponent,
            LoadingOverlay: CircularProgress,
          }}
          loading={isLoading}
          componentsProps={{
            footer: { total },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                8: false,
              },
            },
          }}
          disableSelectionOnClick
          onCellEditCommit={(props, e) => {
            e.defaultMuiPrevented = false;
            const newQty = props.value;
            //get editied cell ingredient id
            const ingredientId = dataGridRows[props.id[0]][8];
            const ingredientSN = dataGridRows[props.id[0]][0];
            editOneIngredient(ingredientId, ingredientSN, newQty);
          }}
          density="compact"
        />
      </div>
    </>
  );
};

export default CustomTable;
