import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import { Backdrop, Fade, Modal, Box, IconButton, Button } from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import CloseIcon from "@mui/icons-material/Close";

import "./css/LabelWithIndicators.css";
import { baseUrl } from "../../api/axiosClient";
import { toFixed } from "../../utils/utils";
import assets from "../../assets";
import { lngs } from "../../utils/constVals";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "45%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "40%",
  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
  borderRadius: "100px 0px 100px 0px",
  overflow: "hidden",
};

const labelColors = {
  low: "#7dc360",
  mid: "#ffad00",
  high: "red",
};
const LabelWithIndicators = (props) => {
  const { t, i18n } = useTranslation();
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState({});
  const [recipePer100Gram, setRecipePer100Gram] = useState({});
  const [servingSize, setservingSize] = useState(1);
  // const [direction, setDirection] = useState(1);
  //((Calories / Total Gram Size ) * Perservingweight * Total Gram Size)/  Total Cooked Weight
  const handleFatColor = () => {
    if (servingSize === 1) {
      // return recipe.total_fat;
      var currentValue = recipe.total_fat;
      // console.log(currentValue + " " + recipe.serving_size);

      if (currentValue > recipe.serving_size * 0.175) return labelColors.high;
      if (currentValue >= recipe.serving_size * 0.031) return labelColors.mid;
      if (currentValue <= recipe.serving_size * 0.03) return labelColors.low;
    } else {
      if (recipePer100Gram.total_fat > 17.5) return labelColors.high;
      if (recipePer100Gram.total_fat >= 3.1) return labelColors.mid;
      if (recipePer100Gram.total_fat <= 3) return labelColors.low;
    }
  };

  const handleSatFatColor = () => {
    if (servingSize === 1) {
      // return recipe.total_fat;
      var currentValue = recipe.saturatedFat;
      if (currentValue > recipe.serving_size * 0.05) return labelColors.high;
      if (currentValue >= recipe.serving_size * 0.016) return labelColors.mid;
      if (currentValue <= recipe.serving_size * 0.015) return labelColors.low;
    } else {
      if (recipePer100Gram.saturatedFat > 5) return labelColors.high;
      if (recipePer100Gram.saturatedFat >= 1.6) return labelColors.mid;
      if (recipePer100Gram.saturatedFat <= 1.5) return labelColors.low;
    }
  };

  const handleSugarsColor = () => {
    if (servingSize === 1) {
      // return recipe.total_fat;
      var currentValue = recipe.totalSugars;
      if (currentValue > recipe.serving_size * 0.225) return labelColors.high;
      if (currentValue >= recipe.serving_size * 0.051) return labelColors.mid;
      if (currentValue <= recipe.serving_size * 0.05) return labelColors.low;
    } else {
      if (recipePer100Gram.totalSugars > 22.5) return labelColors.high;
      if (recipePer100Gram.totalSugars >= 5.1) return labelColors.mid;
      if (recipePer100Gram.totalSugars <= 5.0) return labelColors.low;
    }
  };

  const handleSaltColor = () => {
    if (servingSize === 1) {
      // return recipe.total_fat;
      var currentValue = recipe.Sodium;
      if (currentValue > recipe.serving_size * 0.15) return labelColors.high;
      if (currentValue >= recipe.serving_size * 0.0031) return labelColors.mid;
      if (currentValue <= recipe.serving_size * 0.003) return labelColors.low;
    } else {
      if (recipePer100Gram.Sodium > 1.5) return labelColors.high;
      if (recipePer100Gram.Sodium >= 0.31) return labelColors.mid;
      if (recipePer100Gram.Sodium <= 0.3) return labelColors.low;
    }
  };
  const handleChange = (e) => {
    setservingSize(e.target.value);
    handleFatColor();
  };
  // const handleDirectionChange = (e) => {
  //   setDirection(e.target.value);
  // };

  const getTotalValue = (val, totalWieght, res) => {
    return toFixed(
      ((val / totalWieght) * res[0].recipe.per_serving_weight * totalWieght) /
        res[0].recipe.total_cooked_wieght
    );
  };

  useEffect(() => {
    const getRecipeDetails = async () => {
      if (
        (props.recipeId === undefined || props.recipeId === "") &&
        recipeId === undefined
      )
        return;
      try {
        var url = "";
        if (recipeId !== undefined) {
          url = recipeId;
        } else url = props.recipeId;

        // console.log(URL);
        const { data: res } = await axios.get(
          baseUrl + "ingredients/getall/" + url
        );
        let caloriesSum = 0;
        let saturatedFatSum = 0;
        let totalFatSum = 0;
        let SodiumSum = 0;
        let totalSugarsSum = 0;
        let addedSugarsSum = 0;
        let totalWieght = 0;

        res.forEach((key, value) => {
          totalWieght +=
            parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
            parseFloat(key.qty);

          key.nutriFacts.foodNutrients.map((singleRecipeelm, index) => {
            let currentNutrName = singleRecipeelm.nutrient.name;
            let currentAmount =
              (singleRecipeelm.amount / 100) *
              (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                parseFloat(key.qty));

            if (
              currentNutrName === "Energy" &&
              singleRecipeelm.nutrient.unitName === "kcal"
            ) {
              caloriesSum += currentAmount;
            }
            if (
              currentNutrName.includes("(fat)") ||
              currentNutrName.includes("fatty")
            ) {
              totalFatSum += currentAmount;
            }

            if (currentNutrName === "Fatty acids, total saturated") {
              saturatedFatSum += currentAmount;
            }

            if (currentNutrName === "Sodium, Na") {
              SodiumSum += currentAmount / 1000;
            }

            if (
              currentNutrName === "Sugars, total including NLEA" ||
              currentNutrName === "Sugars, Total"
            ) {
              totalSugarsSum += currentAmount;
            }
            if (currentNutrName === "Sugars, added") {
              //      console.log("A " + currentAmount);
              addedSugarsSum += currentAmount;
            }
          });
          //  console.log(totalSugarsSum + " " + key.name);
        });
        // totalSugarsSum = 0.66;
        const recipeItem = {
          name: res[0].recipe.title,
          serving_size: res[0].recipe.per_serving_weight,
          calories: getTotalValue(caloriesSum, totalWieght, res),
          total_fat: getTotalValue(totalFatSum, totalWieght, res),
          saturatedFat: getTotalValue(saturatedFatSum, totalWieght, res),
          Sodium: getTotalValue(SodiumSum * 2.5, totalWieght, res),
          totalSugars: getTotalValue(totalSugarsSum, totalWieght, res),
          addedSugars: getTotalValue(addedSugarsSum, totalWieght, res),
          // recipeItemDV: {
          //   total_fat: Math.ceil((totalFatSum / 78) * 100),
          //   saturatedFat: Math.ceil((saturatedFatSum / 20) * 100),
          //   Sodium: Math.ceil((SodiumSum / 2300) * 100),
          //   addedSugars: Math.ceil((addedSugarsSum / 50) * 100),
          // },
        };
        // recipeItem.totalSugars = totalSugarsSum;
        //   console.log(totalSugarsSum);

        const recipeItemPer100Gram = {
          name: res[0].recipe.title,
          serving_size: "100",
          calories: toFixed(
            (getTotalValue(caloriesSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          total_fat: toFixed(
            (getTotalValue(totalFatSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          saturatedFat: toFixed(
            (getTotalValue(saturatedFatSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          Sodium: toFixed(
            (getTotalValue(SodiumSum * 2.5, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          totalSugars: toFixed(
            (getTotalValue(totalSugarsSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
          addedSugars: toFixed(
            (getTotalValue(addedSugarsSum, totalWieght, res) /
              recipeItem.serving_size) *
              100
          ),
        };
        setRecipe(recipeItem);
        setRecipePer100Gram(recipeItemPer100Gram);
      } catch (e) {}
    };
    getRecipeDetails();
    handleFatColor();
  }, [props.recipeId]);

  const onClose = async () => {
    try {
      setRecipe({});
      props.onClose();
    } catch (err) {}
  };

  const downloadAsPDF = () => {
    const input = document.getElementById("nutriTable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "l", // landscape
        unit: "pt", // points, pixels won't work properly
        format: [canvas.width, canvas.height], // set needed dimensions for any element
      });
      pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
      // pdf.output('dataurlnewwindow');
      pdf.save(recipe.name + ".pdf");
    });
  };

  var componentRef = useRef(null);
  const pageStyle = "@page { size: 260px 270px }";

  return (
    <>
      <Modal
        open={props.open !== false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={onClose}
      >
        <Fade in={props.open !== false}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                widht: "100%",
              }}
            >
              <QRCode
                value={
                  "https://www.caloriespal.com/label-generator/indicators/" +
                  props.recipeId
                }
                size={64}
                style={{ position: "absolute", top: "50px" }}
              />
              <IconButton
                variant="outlined"
                color="error"
                onClick={(e) => {
                  props.onClose();
                }}
                sx={{
                  marginRight: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="serving-selector"
                  id="serving-selector"
                  onChange={handleChange}
                  defaultValue={1}
                >
                  <MenuItem key={1} value={1}>
                    Per Serving
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Per 100 Gram
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="serving-selector"
                  id="serving-selector"
                  defaultValue={"en"}
                >
                  {Object.keys(lngs).map((lng, ii) => (
                    <MenuItem
                      value={lng}
                      key={ii}
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      {lngs[lng].nativeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button onClick={downloadAsPDF}>Save As PDF</Button>
              <ReactToPrint
                trigger={() => <Button>Print</Button>}
                content={() => componentRef}
                // pageStyle={"@page { size: 700px 250px }"}
              />
            </Box>

            <div
              ref={(el) => {
                componentRef = el;
              }}
              className="classee"
            >
              <Box id="nutriTable" p={3}>
                <Box sx={{ display: "flex" }}>
                  <Box className="text-light mb5 spanny" ml={1}>
                    {recipe.name}
                  </Box>
                  <Box className="text-light mb5 spanny" ml={70}>
                    <strong>Serving size</strong>
                    <strong>
                      (
                      {servingSize === 1
                        ? recipe.serving_size
                        : recipePer100Gram.serving_size}
                      g)
                    </strong>
                  </Box>
                </Box>

                <Box sx={{ display: "flex" }}>
                  <Circle
                    pct={24}
                    size={200}
                    color={"white"}
                    topValue={"calories"}
                    midValue={
                      servingSize === 1
                        ? recipe.calories
                        : recipePer100Gram.calories
                    }
                  />
                  <Circle
                    pct={24}
                    size={200}
                    color={handleFatColor()}
                    topValue={"totalfat"}
                    midValue={
                      servingSize === 1
                        ? recipe.total_fat
                        : recipePer100Gram.total_fat
                    }
                  />

                  <Circle
                    pct={24}
                    size={200}
                    color={handleSatFatColor()}
                    topValue={"SaturatedFat"}
                    midValue={
                      servingSize === 1
                        ? recipe.saturatedFat
                        : recipePer100Gram.saturatedFat
                    }
                  />
                  <Circle
                    pct={24}
                    size={200}
                    color={handleSugarsColor()}
                    topValue={"TotalSugars"}
                    midValue={
                      servingSize === 1
                        ? recipe.totalSugars
                        : recipePer100Gram.totalSugars
                    }
                  />
                  <Circle
                    pct={24}
                    size={200}
                    color={handleSaltColor()}
                    topValue={"Salt"}
                    midValue={
                      servingSize === 1
                        ? recipe.Sodium
                        : recipePer100Gram.Sodium
                    }
                  />
                </Box>
              </Box>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

const Circle = ({ pct, color, topValue, midValue, rangeValue }) => {
  const { t, i18n } = useTranslation();
  const circle = {
    width: "150px !important",
    height: "168px !important",
  };

  const circleFill = {
    background: `linear-gradient(transparent ${100 - pct}%, ${color} ${
      100 - pct
    }%)`,
  };
  return (
    <div className="circle" style={circle}>
      <div className="circle-fill" style={circleFill} />
      <div className="circle-overlay" />
      <div className="circle-fill-text-top"> {t(topValue)}</div>
      <div className="circle-fill-text-middle">{midValue} </div>
      <div className="circle-fill-text-bottom">{rangeValue}</div>
      <div className="circle-divider">_____________________________</div>
    </div>
  );
};

export default LabelWithIndicators;
