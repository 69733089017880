import React from "react";
import { useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

import assets from "../assets";
import {
  Backdrop,
  Fade,
  Modal,
  Box,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import QRCode from "react-qr-code";
import ApexCharts from "apexcharts";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { toFixed } from "../utils/utils";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "500px",

  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
  borderRadius: "100px 0px 100px 0px",
  overflow: "hidden",
};

const DonutModal = (props) => {
  var options = {
    series: [],
    chart: {
      type: "donut",
      width: 500,
    },
    labels: ["Calories", "Proteins", "Carbohydrates", "Total Fat"],
    fill: {
      type: "gradient",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              label: "Calories",
              color: "#0065bd",
              formatter: function (w) {
                return "1";
              },
            },
          },
        },
      },
    },
  };

  const [isLoaded, setisLoaded] = useState(false);

  useEffect(() => {
    if (props.recipe === undefined) return;
    try {
      if (!isLoaded && props.recipe !== undefined)
        setTimeout(() => {
          var element = document.getElementById("donutChart");

          if (typeof element != "undefined" && element != null) {
            // Exists.
            var chart = new ApexCharts(
              document.querySelector("#donutChart"),
              options
            );

            chart.render();
            setisLoaded(true);
            setisLoaded(false);
          }
          // ,
          // "carbohydrate, by summation",
          // "total fat (nlea)",

          var caloriesTotalValuePerRecipe = 0;
          var proteinTotalValuePerRecipe = 0;
          var carbsTotalValuePerRecipe = 0;
          var totalFatTotalValuePerRecipe = 0;
          props.recipe?.ingredients?.forEach((key, value) => {
            key.nutriFacts[0].foodNutrients.map((singleRecipeelm, index) => {
              if (
                singleRecipeelm.nutrient.name === "Energy" &&
                singleRecipeelm.nutrient.unitName === "kcal"
              ) {
                caloriesTotalValuePerRecipe += toFixed(
                  (singleRecipeelm.amount / 100) *
                    (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                      parseFloat(key.qty))
                );
              }
              if (singleRecipeelm.nutrient.name === "Protein") {
                proteinTotalValuePerRecipe += toFixed(
                  (singleRecipeelm.amount / 100) *
                    (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                      parseFloat(key.qty))
                );
              }
              if (
                singleRecipeelm.nutrient.name === "Carbohydrate, by difference"
              ) {
                carbsTotalValuePerRecipe += toFixed(
                  (singleRecipeelm.amount / 100) *
                    (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                      parseFloat(key.qty))
                );
              }
              if (singleRecipeelm.nutrient.name === "Total fat (NLEA)") {
                totalFatTotalValuePerRecipe += toFixed(
                  (singleRecipeelm.amount / 100) *
                    (parseFloat(key.serving_unit.match(/\((.*)\)/).pop()) *
                      parseFloat(key.qty))
                );
              }
            });
          });

          chart.updateSeries([
            toFixed(caloriesTotalValuePerRecipe),
            toFixed(proteinTotalValuePerRecipe),
            toFixed(carbsTotalValuePerRecipe),
            toFixed(totalFatTotalValuePerRecipe),
          ]);
          chart.updateOptions({
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 5,
                    },
                    total: {
                      show: true,
                      showAlways: true,
                      fontSize: "22px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      fontWeight: 600,
                      label: "Calories",
                      color: "#0065bd",
                      formatter: function (w) {
                        return toFixed(caloriesTotalValuePerRecipe);
                      },
                    },
                  },
                },
              },
            },
          });
        }, 100);
    } catch (err) {}
  }, [props.recipe]);

  const onClose = async () => {
    try {
      props.onClose();
      setisLoaded(false);
    } catch (err) {}
  };

  return (
    <>
      <Modal
        open={props.open !== false}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        onClose={onClose}
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              widht: "100%",
            }}
          >
            <IconButton
              variant="outlined"
              color="error"
              onClick={(e) => {
                props.onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h4" ml={3}>
            {props.recipe?.title}
          </Typography>
          <Divider></Divider>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            pt={5}
          >
            <Box id="donutChart"></Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DonutModal;
