import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Box,
  TextField,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import recipeApi from "../../api/recipeApi";
import { toast } from "react-toastify";
import { toastErrorOptions, toastSuccessOptions } from "../../utils/constVals";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import LiveSearchFilter from "../LiveSearchFilter";
import Paper from "@mui/material/Paper";
import "../../css/custom.scrollbar.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import CardMedia from "@mui/material/CardMedia";

import assets from "../../assets";
import CustomTable from "../customtable/CustomTable";
import {
  allergies,
  ingredientTableHeaders,
  nutritionValuesHeaders,
} from "../../utils/tablesHeaders";
import { toFixed } from "../../utils/utils";
import ingredientApi from "../../api/ingredientApi";

import Chip from "@mui/material/Chip";
import { assetBaseUrl } from "../../api/axiosClient";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const modalStyle = {
  outline: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "95%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 1,
  height: "95%",
  backgroundColor: assets.colors.secondaryy,
  overflowX: "none",
  overflowY: "scroll",
};

let timer;
const timeout = 500;
let isModalClosed = false;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const RecipeModal = (props) => {
  const boardId = props.boardId;
  const [recipe, setRecipe] = useState(props.recipe);
  const [title, setTitle] = useState("");
  const [recipeItem, setRecipeItem] = useState(undefined);
  const [ingredentToAdd, setingredentToAdd] = useState();

  //recipe pricate details
  const [typeVegNonVeg, setTypeVegNonVeg] = useState();
  const [total_cooked_wieght, settotal_cooked_wieght] = useState();
  const [per_serving_weight, setper_serving_weight] = useState();
  const [status_recipe, setstatus_recipe] = useState();
  const [descripton, setdescripton] = useState();

  const [ingredientsRows, setIngredientsRows] = useState([]);
  const [nutritionsRows, setNutritionsRows] = useState([]);

  const [allergiensFinalList, setAllergiensFinalList] = useState([]); //Ingredient Table Footer Sum

  const [updateItems, setUpdateItems] = useState();
  // const boards = useSelector((state) => state.board.value)
  const [addFlag, setAddFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewIgredientRow = async (row) => {
    setIngredientsRows([...ingredientsRows, ...row]);
    setAddFlag(true);
  };

  const addNewNutritionsRow = async (row) => {
    setNutritionsRows([...nutritionsRows, ...row]);
  };

  useEffect(() => {
    const addNewIngredientsRows = (row) => {
      if (ingredientsRows.length !== 0) setIngredientsRows([...row]);
      else setIngredientsRows([...ingredientsRows, ...row]);
    };

    const addNewNutritionsRows = (row) => {
      if (nutritionsRows.length !== 0) setNutritionsRows([...row]);
      else setNutritionsRows([...nutritionsRows, ...row]);
    };
    const getThem = async () => {
      if (recipe !== undefined)
        try {
          setLoading(true);
          const recipeIngredients = await recipeApi.getRecipeIngredients(
            boardId,
            recipe?.id
          );

          // console.log(recipeIngredients)
          // nutritionValuesHeaders.slice(1)
          if (recipeIngredients !== undefined) {
            var ingredientsRows = [];
            var nutritionsRows = [];
            var nutritionsSumRow = new Array(
              nutritionValuesHeaders.length
            ).fill(0);
            var ingredientsSumRow = new Array(ingredientTableHeaders.length);
            ingredientsSumRow[4] = 0;
            ingredientsSumRow[6] = 0;
            var adjFinalallergiesList = [];

            var cellIndex = 0;
            for (let index = 0; index < recipeIngredients.length; index++) {
              if (isModalClosed === false) {
                //Ingredeitns Rows
                let p = recipeIngredients[index];
                var currentTotalQuantity =
                  parseFloat(p.serving_unit.match(/\((.*)\)/).pop()) *
                  parseFloat(p.qty);
                var currentTotalCost = 0;
                var costPerGram = 0;
                ingredientsSumRow[4] += currentTotalQuantity;
                if (
                  !(
                    isNaN(p.cost_yield) ||
                    p.cost_yield === 0 ||
                    isNaN(p.cost) ||
                    p.cost === 0 ||
                    isNaN(p.cost_per_gram) ||
                    p.cost_per_gram === 0
                  )
                ) {
                  if (p.cost_yield === 0) p.cost_yield = 100;
                  currentTotalCost =
                    (p.cost / p.cost_per_gram) *
                    (100 / p.cost_yield) *
                    parseFloat(p.serving_unit.match(/\((.*)\)/).pop()) *
                    parseFloat(p.qty);
                  ingredientsSumRow[6] += currentTotalCost;
                  costPerGram =
                    (p.cost / p.cost_per_gram) * (100 / p.cost_yield);
                }

                const recipeDetails = [
                  p.name,
                  p.subrecipe === null ? "" : p.subrecipe.title,
                  p.serving_unit,
                  p.qty,
                  currentTotalQuantity,
                  costPerGram + " AED",
                  currentTotalCost + " AED",
                  p.id,
                ];

                //check matched allergiens
                for (
                  var allergiesIndex = 0;
                  allergiesIndex < allergies.length;
                  allergiesIndex++
                ) {
                  const ingredientName = p.name.toLowerCase();
                  const allergyName = allergies[allergiesIndex].toLowerCase();
                  if (ingredientName.includes(allergyName))
                    adjFinalallergiesList.push(allergyName);
                }
                ingredientsRows.push(recipeDetails);
                //nutritions values
                // console.log(recipeIngredients[index])
                var tmp = new Array(nutritionValuesHeaders.length).fill(0);

                for (const nutriObj of recipeIngredients[index].nutriFacts[0]
                  .foodNutrients) {
                  let nutrName = nutriObj.nutrient.name.toLowerCase();
                  cellIndex = 0;
                  for (let k of nutritionValuesHeaders.slice(1)) {
                    if (
                      k === nutrName &&
                      tmp[cellIndex] === 0 &&
                      nutriObj.nutrient.unitName !== "kJ"
                    ) {
                      //divide on 100 beacuse USDA gives serving per 100 Gram
                      var totalQty =
                        (nutriObj.amount / 100) *
                        (parseFloat(p.serving_unit.match(/\((.*)\)/).pop()) *
                          parseFloat(p.qty));
                      totalQty = toFixed(totalQty);
                      tmp[cellIndex] = totalQty;
                    }
                    cellIndex++;
                  }
                }

                nutritionsRows.push(tmp);
              }
            }
            nutritionsRows.forEach((arr, index) => {
              arr.forEach((element, ind) => {
                nutritionsSumRow[ind] += element;
                nutritionsSumRow[ind] = toFixed(nutritionsSumRow[ind]);
              });
            });
            nutritionsRows.push(nutritionsSumRow);
            // fix total_cooked_wieght in case a new ingredient added
            if (props.recipe.total_cooked_wieght === 0 || addFlag === true)
              settotal_cooked_wieght(ingredientsSumRow[4]);
            if (addFlag === true) setAddFlag(false);
            //end
            ingredientsSumRow[4] += " Gram";
            ingredientsSumRow[6] += " AED";
            ingredientsRows.push(ingredientsSumRow);
            setAllergiensFinalList(adjFinalallergiesList);

            // console.log(adjFinalallergiesList);
            addNewIngredientsRows(ingredientsRows);
            addNewNutritionsRows(nutritionsRows);
          }
        } catch (err) {
          console.log(err);
        }
    };
    getThem();
    const getRecipeImage = async () => {
      try {
        const recipePhoto = await recipeApi.getPhoto(boardId, recipe?.id);
        if (recipePhoto !== "") {
          setAvatarImage(assetBaseUrl + recipePhoto);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    if (recipe !== undefined) getRecipeImage();
  }, [recipe, recipeItem, updateItems]);

  useEffect(() => {
    setRecipe(props.recipe);

    //  recipe.type = typeVegNonVeg;
    //   recipe.total_cooked_wieght =  total_cooked_wieght ;
    //   recipe.per_serving_weight = per_serving_weight  ;
    //   recipe.status_recipe = status_recipe  ;
    //   recipe.descripton =   descripton;
    if (props.recipe !== undefined) {
      setTitle(props.recipe !== undefined ? props.recipe.title : "");
      setTypeVegNonVeg(props.recipe.type);
      settotal_cooked_wieght(props.recipe.total_cooked_wieght);
      setper_serving_weight(props.recipe.per_serving_weight);
      setstatus_recipe(props.recipe.status_recipe);
      setdescripton(props.recipe.descripton);
    }

    if (props.recipe !== undefined) {
      isModalClosed = false;
    }
  }, [props.recipe]);

  const onClose = async () => {
    try {
      setingredentToAdd(undefined);
      isModalClosed = true;
      props.onUpdate(recipe);
      props.onClose();
    } catch (err) {
      toast.success(err, toastErrorOptions);
    }
  };

  //Update Recipe Title
  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    const newDescripton = e.target.value;
    timer = setTimeout(async () => {
      try {
        await recipeApi.update(boardId, recipe.id, { title: newTitle });
        toast.success("Title Updated", toastSuccessOptions);
      } catch (err) {
        toast.error(err, toastErrorOptions);
      }
    }, timeout);
    recipe.title = newTitle;
    setTitle(newTitle);
    props.onUpdate(recipe);
  };
  //update typ(Veg,notveg), description,serving weight,etc.....
  const updateRecipeDetails = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    const newDescripton = e.target.value;
    timer = setTimeout(async () => {
      try {
        await recipeApi.update(boardId, recipe.id, {
          type: typeVegNonVeg,
          total_cooked_wieght: total_cooked_wieght,
          per_serving_weight: per_serving_weight,
          status_recipe: status_recipe,
          descripton: descripton,
        });
        toast.success("Title Updated", toastSuccessOptions);
      } catch (err) {
        toast.error(err, toastErrorOptions);
      }
    }, timeout);

    recipe.type = typeVegNonVeg;
    recipe.total_cooked_wieght = total_cooked_wieght;
    recipe.per_serving_weight = per_serving_weight;
    recipe.status_recipe = status_recipe;
    recipe.descripton = descripton;
    props.onUpdate(recipe);
  };

  let sizeofM = 3;

  useEffect(() => {
    var numberOfNutritionsRows = [...nutritionsRows].length;
    if (numberOfNutritionsRows === 1) setNutritionsRows([[]]);
  }, [ingredientsRows]);

  const deleteOneIngredient = async (ingredientId) => {
    try {
      const res = await ingredientApi.deleteOne(
        boardId,
        recipe.id,
        ingredientId
      );
      toast.success("Ingredient Deleted", toastSuccessOptions);
    } catch (err) {
      toast.error(err.data, toastErrorOptions);

      // console.log(err)
    } finally {
      setUpdateItems([]);
    }
  };

  const editOneIngredient = async (ingredientId, index, newQty) => {
    try {
      const res = await ingredientApi.update(boardId, recipe.id, ingredientId, {
        qty: newQty,
      });
      index--;
      setUpdateItems([]);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleChangeImage = async (e) => {
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    if (e.target.files[0] === undefined) return;

    try {
      const res = await recipeApi.updatePhoto(formData, boardId, recipe.id);
      console.log(res.data);
      // setAvatarImage(assetBaseUrl + "/profilesPhotos/" + res.data);
      setAvatarImage(assetBaseUrl + "recipesPhotos/" + res.data);
    } catch (err) {
      console.log(err + " Can't upload image");
    }
  };

  const [avatarImage, setAvatarImage] = useState("");

  return (
    <Modal
      open={recipe !== undefined}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      onClose={onClose}
    >
      <Fade in={recipe !== undefined}>
        <Box sx={modalStyle}>
          {!addFlag && loading ? (
            <Stack spacing={3}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="circular" width={100} height={100} />
              <Skeleton variant="rectangular" width={"100%"} height={"20vh"} />
              <Skeleton variant="rounded" width={"100%"} height={"50vh"} />
            </Stack>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  widht: "100%",
                }}
              >
                <IconButton
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    onClose();
                    props.closeModalBtn(undefined);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  padding: "0rem 5rem 0rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "300px",
                      height: "180px",
                      boxShadow: 5,
                      "&:hover": {
                        boxShadow: 20,
                        transition: "0.5s",
                      },
                    }}
                    image={avatarImage}
                    alt="green iguana"
                  />
                  <Button
                    component="label"
                    onChange={async (e) => {
                      handleChangeImage(e);
                    }}
                    sx={{
                      position: "absolute",
                      background: "black",
                      "&:hover": {
                        background: "gray",
                        transition: "0.5s",
                      },

                      borderRadius: "0px 0px 0px 20px",
                    }}
                  >
                    <ChangeCircleIcon
                      sx={{
                        color: "gray",
                        fontSize: "30px",
                        "&:hover": {
                          color: "black",
                          transition: "0.5s",
                        },
                      }}
                    ></ChangeCircleIcon>
                    <input type="file" accept=".jpg, .jpeg, .png" hidden />
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    padding: "0rem 5rem 0rem",
                  }}
                >
                  <TextField
                    value={title}
                    placeholder="Name"
                    variant="outlined"
                    onChange={updateTitle}
                    fullWidth
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": { padding: 0 },
                      "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "2.5rem",
                        fontWeight: "700",
                      },
                      marginBottom: "10px",
                    }}
                  />
                  {/* TODO */}
                  {/* <Typography variant="body2" fontWeight="700">
                {recipe !== undefined
                  ? Moment(recipe.createdAt).format("YYYY-MM-DD")
                  : ""}
              </Typography> */}
                  {/* <Divider sx={{ margin: "1.5rem 0" }} /> */}
                </Box>
              </Box>
              <Divider sx={{ margin: "1.5rem 0" }} />

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  padding: "0rem 5rem 0rem",
                }}
              >
                {/* Recipe Values */}
                <FormControl sx={{ m: sizeofM, width: "25ch" }}>
                  <InputLabel id="recipetype">Select Type</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="recipe-type"
                    id="recipetype"
                    defaultValue={"Veg"}
                    label="recipe-type"
                    onChange={(e) => setTypeVegNonVeg(e.target.value)}
                    value={typeVegNonVeg}
                  >
                    <MenuItem key={1} value={"Veg"}>
                      Veg
                    </MenuItem>
                    <MenuItem key={2} value={"Non-Veg"}>
                      Non-Veg
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="standard"
                  size="small"
                  type="number"
                  label="Total Cooked Weight"
                  id="totalcookedweight"
                  sx={{ m: sizeofM, width: "25ch" }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => settotal_cooked_wieght(e.target.value)}
                  required
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">gm</InputAdornment>
                    ),
                    min: 1,
                  }}
                  value={total_cooked_wieght}
                />
                <TextField
                  variant="standard"
                  size="small"
                  type="number"
                  label="Per Serving Weight"
                  id="totalcookedweight"
                  sx={{ m: sizeofM, width: "25ch" }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setper_serving_weight(e.target.value)}
                  required
                  InputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">gm</InputAdornment>
                    ),
                    min: 1,
                  }}
                  value={per_serving_weight}
                />
                <FormControl sx={{ m: sizeofM, width: "25ch" }}>
                  <InputLabel id="recipetype">Select Status</InputLabel>
                  <Select
                    size="small"
                    variant="standard"
                    labelId="recipe-type"
                    id="recipetype"
                    defaultValue={1}
                    label="recipe-type"
                    onChange={(e) => setstatus_recipe(e.target.value)}
                    value={status_recipe}
                  >
                    <MenuItem value={1}>Live</MenuItem>
                    <MenuItem value={2}>Draft</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="standard"
                  size="small"
                  id="servingdescription"
                  label="Serving Description"
                  sx={{ m: sizeofM, width: "25ch" }}
                  onChange={(e) => setdescripton(e.target.value)}
                  value={descripton}
                />
                <Button color="success" onClick={updateRecipeDetails}>
                  Save
                </Button>
              </Box>

              <Box
                sx={{
                  display: "block",
                  alignItems: "ceter",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  padding: "0rem 5rem 0rem",
                }}
              >
                <Divider sx={{ margin: "1.0rem 0" }} />

                <LiveSearchFilter
                  recipeItem={(tmpRecipe) => setRecipeItem(tmpRecipe)}
                  newRow={(row) => addNewIgredientRow([row])}
                  newNutritionRow={(row) => addNewNutritionsRow([row])}
                  sendRecipeItem={recipe}
                  boardId={boardId}
                  addIngredientToTable={ingredentToAdd}
                  // typeVegNonVeg={typeVegNonVeg}
                  // total_cooked_wieght={total_cooked_wieght}
                  // per_serving_weight={per_serving_weight}
                  // status_recipe={status_recipe}
                  // descripton={descripton}
                  updateTables={([]) => setUpdateItems([])}
                />
                <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Item>
                        <CustomTable
                          headers={ingredientTableHeaders}
                          rows={ingredientsRows}
                          recipe={recipe}
                          deleteOneIngredient={(ingredientId) =>
                            deleteOneIngredient(ingredientId)
                          }
                          editOneIngredient={(ingredientId, ingredientSN, x) =>
                            editOneIngredient(ingredientId, ingredientSN, x)
                          }
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <CustomTable
                          headers={nutritionValuesHeaders}
                          rows={nutritionsRows}
                          recipe={recipe}
                        />
                      </Item>
                    </Grid>
                    <Box pt={2}>
                      <Typography variant="h6">Allergies </Typography>

                      <Divider></Divider>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {allergies.map((key, index) => {
                          for (var x = 0; x < allergiensFinalList.length; x++) {
                            if (
                              allergiensFinalList[x]
                                .toLowerCase()
                                .includes(key.toLowerCase())
                            ) {
                              return (
                                <Box m={0.5} key={index}>
                                  <Chip
                                    label={key}
                                    color="success"
                                    deleteIcon={<DoneIcon />}
                                    onDelete={() => {}}
                                    size="small"
                                    key={index}
                                  />
                                </Box>
                              );
                            }
                          }
                          return (
                            <Box m={0.5} key={index}>
                              <Chip label={key} key={index} size="small" />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default RecipeModal;
