import { Container, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
// import assets from '../../assets'

const AuthLayout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthincated = await authUtils.isAuthenricated();
      if (!isAuthincated) {
        setLoading(false);
      } else {
        navigate("/");
      }
    };
    checkAuth();
  }, [navigate]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* <img src={assets.images.logoDark} style={{width:'100px'}} alt='app logo' /> */}
        <img
          src={process.env.PUBLIC_URL + "/assets/images/Calories (1).png"}
          alt="logo"
          height="10"
        />
        <Outlet />
      </Box>
    </Container>
  );
};

export default AuthLayout;
