import axiosClient from "./axiosClient";

const ingredientApi = {
  create: (boardId, recipeId, params) =>
    axiosClient.post(
      `boards/${boardId}/recipes/${recipeId}/ingredients`,
      params
    ),
  getAll: (boardId, recipeId) =>
    axiosClient.get(`boards/${boardId}/recipes/${recipeId}/ingredients`),
  deleteOne: (boardId, recipeId, ingredientId) =>
    axiosClient.delete(
      `boards/${boardId}/recipes/${recipeId}/ingredients/${ingredientId}`
    ),
  update: (boardId, recipeId, ingredientId, params) =>
    axiosClient.put(
      `boards/${boardId}/recipes/${recipeId}/ingredients/${ingredientId}`,
      params
    ),
  getCosts: (userId) => axiosClient.get(`ingredients/getall?userId=${userId}`),
  updateCost: (boardId, recipeId, ingredientId, userId, params) =>
    axiosClient.put(
      `boards/${boardId}/recipes/${recipeId}/ingredients/edit/${ingredientId}?userId=${userId}`,
      params
    ),
};

export default ingredientApi;
