import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";

const Signup = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("username").trim();
    const password = data.get("password").trim();
    const confirmPassword = data.get("confirmPassword").trim();
    const name = data.get("name").trim();
    const expirydate = data.get("expirydate").trim();
    const website = data.get("website").trim();

    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }
    if (password !== confirmPassword) {
      err = true;
      setConfirmPasswordErrText("Confirm password not match");
    }

    if (err) return;

    setLoading(true);
    try {
      const res = await authApi.signup({
        username,
        password,
        confirmPassword,
        name,
        expirydate,
        website,
      });
      setLoading(false);
      localStorage.setItem("token", res.token);
      navigate("/");
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setConfirmPasswordErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{ mt: 1 }}
        onSubmit={handleSubmit}
        noValidate
        className="flex flex-col justify-center items-center"
      >
        <p className="text-3xl font-[800]">Add a new User</p>
        <div className="w-2/12">
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="name"
            name="name"
            disabled={loading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="expirydate"
            label="expirydate"
            name="expirydate"
            disabled={loading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="website"
            label="website"
            name="website"
            disabled={loading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            disabled={loading}
            error={usernameErrText !== ""}
            helperText={usernameErrText}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            disabled={loading}
            error={passwordErrText !== ""}
            helperText={passwordErrText}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            disabled={loading}
            error={confirmPasswordErrText !== ""}
            helperText={confirmPasswordErrText}
          />
          <LoadingButton
            sx={{ mt: 3, mb: 2 }}
            variant="outlined"
            fullWidth
            color="success"
            type="submit"
            loading={loading}
          >
            Signup
          </LoadingButton>
        </div>
      </Box>
    </>
  );
};

export default Signup;
